import { React, useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import aqueductIcon from "../images/aqueduct_icon.svg"
import APIService from '../components/APIService'
import { Cog6ToothIcon, ArrowLeftOnRectangleIcon, DocumentMagnifyingGlassIcon, BanknotesIcon, } from '@heroicons/react/24/outline'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button'

export default function ToolsSidebar({ removeToken }) {

  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  function logOut(event) {
    event.preventDefault();
    APIService.LogOut().then(resp => {
      removeToken();
      navigate("/");
    })
    .catch(error => console.log(error));
  }

  return (
    <div>
      <div className="flex flex-col justify-between w-20 min-h-screen h-full bg-[#C5D7E9] p-4">
        <div className="flex flex-col justify-center px-1">
          <img className="mt-6 pointer-events-none" src={aqueductIcon} />
          {/* <div title={'Dashboard'} className={"flex flex-row mt-6 item-center hover:bg-[#b0cce8] p-2 rounded cursor-pointer " + (location.pathname.includes("/dashboard") ? "bg-[#b0cce8]" : "")} onClick={() => onNav("/dashboard")}>
            <MapIcon className="h-6 w-6"/>
          </div> */}
          <div title={'Tasks'} className={"flex flex-row mt-6 item-center hover:bg-[#b0cce8] p-2 rounded cursor-pointer " + (location.pathname.includes("/tasks") ? "bg-[#b0cce8]" : "")} onClick={() => onNav("/tasks")}>
            <DocumentMagnifyingGlassIcon className="h-6 w-6"/>
          </div>
          <div title={'Shifts'} className={"flex flex-row mt-6 item-center hover:bg-[#b0cce8] p-2 rounded cursor-pointer " + (location.pathname.includes("/shifts") ? "bg-[#b0cce8]" : "")} onClick={() => onNav("/shifts")}>
            <BanknotesIcon className="h-6 w-6" />
          </div>
          <div title={'Settings'} className={"flex flex-row mt-6 item-center hover:bg-[#b0cce8] p-2 rounded cursor-pointer " + (location.pathname.includes("/settings") ? "bg-[#b0cce8]" : "")} onClick={() => onNav("/settings")}>
            <Cog6ToothIcon className="h-6 w-6" />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <hr className="w-full mb-4 border-1 border-grey-2"/>
          <div title="Log out" className="hover:cursor-pointer" onClick={logOut}>
            <ArrowLeftOnRectangleIcon className="h-6 w-6"/>
          </div>
        </div>
      </div>
    </div>
  )
}