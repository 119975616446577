import React from "react";


export default function TextInput({
    value,
    onChange,
    placeholder,
    error="",
    title="",
    type="text",
  }) {
    if (error == "") {
      var textInputClass = 'border-[#DFDFDF] rounded-md border-2 pl-4 py-2';
    } else {
      var textInputClass = 'border-[#A22D23] rounded-md border-2 pl-4 py-2 bg-[#FDF6F6]';
    }
    return (
      <div className={"flex flex-col"}>
        {title !== '' && (<h4 className="mb-2">{title}</h4>)}
        <input
          placeholder={placeholder}
          onChange={onChange}
          className={textInputClass}
          value={value}
          type={type}
        >
        </input>
        {error !== '' && (<div className="text-start mt-2 text-[#A22D23]">{error}</div>)}
      </div>
    )
  }