import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import Workflows from "../routes/workflows";
import Workflow from "../routes/workflow";
import WorkflowUpdate from '../routes/workflow_update';
import Documents from "../routes/documents";
import Settings from "../routes/settings";
import AppLogin from "../routes/app_login";
import Playground from "../routes/playground";
import Tasks from "../routes/tasks";
import Task from "../routes/task";
import useToken from '../utils/UseToken';
import TaskUpdate from '../routes/task_update';


export default function AppRouter() {

  const { saveToken, token, removeToken, isTokenSet } = useToken();

  function RequireAuth({ children }) {
    const location = useLocation();
    const authed = isTokenSet();
    return authed === true ? children : <Navigate to="/login" state={{ path: location.pathname }} />;
  }

  return (
    <div className="AppRouter">
      <Routes>
        <Route path="tasks" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Tasks token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="tasks/:taskUUID" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Task token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="tasks/create" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <TaskUpdate token={token} removeToken={removeToken} saveToken={saveToken} isCreate={true}/>
            </div>
          </RequireAuth>
        } />
        <Route path="workflows" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Workflows token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="workflows/:workflowUUID" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Workflow token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="workflows/create" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <WorkflowUpdate token={token} removeToken={removeToken} saveToken={saveToken} isCreate={true}/>
            </div>
          </RequireAuth>
        } />
        <Route path="workflows/:workflowUUID/edit" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <WorkflowUpdate token={token} removeToken={removeToken} saveToken={saveToken} isCreate={false}/>
            </div>
          </RequireAuth>
        } />
        <Route path="documents" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Documents token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="settings" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Settings token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="playground" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Playground token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="/" element={ !isTokenSet() ? <AppLogin saveToken={saveToken}/> : <Navigate to="/tasks"/> } />
        <Route path="/login" element={ !isTokenSet() ? <AppLogin saveToken={saveToken}/> : <Navigate to="/tasks"/> } />
      </Routes>
    </div>
  )
}