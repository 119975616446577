

function currencyUtils() {
    return {
      formatCurrency(moneyObj) {
        // moneyObj is an object of form {'currency': 'USD', 'amount': 100} 
        // amount is in the base unit of the currency, for USD it would be cents
        if (moneyObj.currency === 'USD') {
            var moneyDecimal = (moneyObj.amount / 100).toFixed(2)
            if (moneyDecimal.endsWith('.00')) {
                return '$' + moneyDecimal.slice(0, moneyDecimal.length - 3)
            } else {
                return '$' + moneyDecimal
            }
        } else {
          return ''
        }
      }
    }
  }
  
  export default currencyUtils;