import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'
import CookieCrumbNav from '../components/CookieCrumbNav'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button'
import '../index.css'


export default function Workflow({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const location = useLocation();

  const cookieCrumbNavPaths = [
    {
        'name': 'Workflows',
        'location': '/workflows',
    }
  ]

  const { workflowUUID } = useParams()
  const [workflow, setWorkflow] = useState(null)

  useEffect(() => {
    APIService.GetWorkflowByUUID(token, workflowUUID).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setWorkflow(resp.workflow)
    })
  }, [token])

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <div className="flex flex-col px-12 py-6 w-full">
          {workflow !== null && <CookieCrumbNav paths={cookieCrumbNavPaths} currentPathName={workflow !== null ? workflow.name : ''} /> }
          <div className="flex flex-row justify-between mt-6">
            <h1>{workflow !== null ? workflow.name : ''}</h1>
            <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.SECONDARY_BLUE} onClick={() => onNav('/workflows/' + workflow.uuid + '/edit')} label={"Edit"}/>
          </div>
          <hr className="my-4 border-1 border-grey-2"/>
          {workflow && (
            <div className="flex flex-col text-start">
              <h2>Details</h2>
              <div>
                <div className="flex flex-col mt-4">
                  <div>
                    <h4>Output type</h4>
                  </div>
                  <div className="mt-2">
                    {workflow.output.label}
                  </div>
                </div>
                <div className="flex flex-col items center mt-4">
                  <div>
                    <h4>
                      {workflow.review_types.length > 1 ? "Review types" : "Review type"}
                    </h4>
                    {workflow.review_types.length !== 0 ? (
                      <div className="mt-2">
                        <ul>
                          {workflow.review_types.map((reviewType, idx) => (
                            <li key={idx}
                                className={`inline-block mr-2 py-[3px] px-2 text-sm leading-[17px] rounded-[3px] text-white bg-indigo-500 overflow-hidden`}
                            >
                              {reviewType.label}
                            </li>
                            ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="mt-2">
                        None
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <h2>Fields</h2>
              </div>
              <div className="flex flex-col text-start mt-4">
                <div className="flex flex-row">
                  <h4 className="w-1/6">Name</h4>
                  <h4 className="w-1/6">Type</h4>
                  <h4 className="w-1/6">Output field</h4>
                  <h4 className="w-1/2">Description</h4>
                </div>
                <hr className="my-4 border-1 border-grey-2"/>
                <div className="text-gray-800">
                  {workflow.fields.map((field, index) => (
                    <div className="flex flex-row mb-4">
                      <div className="w-1/6">{field['name']}</div>
                      <div className="w-1/6">{field.type.label}</div>
                      <div className="w-1/6">{field.output_label}</div>
                      <div className="w-1/2">{field.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}