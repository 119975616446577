import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'
import TaskStatusTag from '../shared/TaskStatusTag'
import aqueductLogo from "../images/aqueduct_logo.svg"
import { Oval } from 'react-loader-spinner'
import Dropdown from 'react-dropdown';
import { PlusCircleIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid'


export default function Documents({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const [documents, setDocuments] = useState([])

//   useEffect(() => {
//     APIService.GetTasks(token).then((resp) => {
//       if (resp.msg === 'Token has expired') {
//         removeToken()
//         navigate('/login')
//       }
//       if (!!resp.access_token) {
//         saveToken(resp.access_token)
//       }
//       setTasks(resp.tasks)
//       setTaskStatusCounts(taskStatusCounter(resp.tasks))
//     })
//   }, [token])


  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <div className="flex flex-col w-full p-12">
          <div className="flex flex-col text-start mt-6">
            <div>
                Documents page
            </div>
            {documents.map((document, index) => (
              <div className={"flex flex-row mt-2 p-4 rounded-lg border-2 items-center " + (index % 2 == 0 ? "bg-stone-100" : "")}>
                <div className="w-1/6">document {index}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}