import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'
import SubtaskModal from '../components/SubtaskModal'
import TaskStatusTag from '../shared/TaskStatusTag'
import SubtaskStatusTag from '../shared/SubtaskStatusTag'
import { ChevronDownIcon, ChevronUpIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import TaskStatus from '../components/TaskStatus';
import CookieCrumbNav from '../components/CookieCrumbNav'
import '../index.css'


export default function Task({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()

  const { taskUUID } = useParams()
  const [task, setTask] = useState(null)
  const [showWorkflowFields, setShowWorkflowFields] = useState(false)
  const [subtaskStatuses, setSubtaskStatus] = useState({})
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSubtask, setSelectedSubtask] = useState(null);

  const cookieCrumbNavPaths = [
    {
        'name': 'Tasks',
        'location': '/tasks',
    }
  ]

  function statusCounter(tasks) {
    var res = tasks.reduce(function(obj, v) {
      // increment or set the property
      // `(obj[v.status] || 0)` returns the property value if defined
      // or 0 ( since `undefined` is a falsy value
      obj[v.status] = (obj[v.status] || 0) + 1;
      // return the updated object
      return obj;
      // set the initial value as an object
    }, {})
    return res
  }

  useEffect(() => {
    APIService.GetTaskByUUID(token, taskUUID).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setTask(resp.task)
      setSubtaskStatus(statusCounter(resp.task.subtasks))
    })
  }, [token, selectedSubtask])

  function onResultDownload(taskUUID) {
    APIService.GetResultsLink(token, {taskUUID}).then(resp => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      const link = document.createElement('a');
      link.href = resp.presigned_url;
      link.setAttribute('download', `${taskUUID}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => console.log(error));
  }

  function onShowWorkflowFields() {
    setShowWorkflowFields(true)
  }

  function onHideWorkflowFields() {
    setShowWorkflowFields(false)
  }

  function openSubtaskModal(subtask) {
    setSelectedSubtask(subtask);
    setModalIsOpen(true);
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <SubtaskModal
          token={token} removeToken={removeToken} saveToken={saveToken}
          task={task} subtask={selectedSubtask} setSubtask={setSelectedSubtask}
          modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}
        />
        <div className="flex flex-col px-12 py-6 w-full">
          <CookieCrumbNav paths={cookieCrumbNavPaths} currentPathName={task !== null ? task.name : ''} />
          <div className="flex flex-row mt-6">
            <h1>Task: {task !== null ? task.name : ''}</h1>
          </div>
          <hr className="mt-4 border-1 border-grey-2"/>
          {task && (
            <div className="flex flex-col text-start">
              <div className="grid grid-cols-2 gap-[30px]">
                <div className="col-span-1 flex flex-col mt-4 text-start">
                  <div>
                    <h4>Submitted</h4>
                    <div className="mt-2">
                      {new Date(task.created_at + ' UTC').toLocaleString()}
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4>Created by</h4>
                    <div className="mt-2">
                      {task.creator_email}
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4>Documents</h4>
                    <div className="mt-2">
                      {task.original_filename}
                    </div>
                  </div>
                </div>
                <div className="col-span-1 mt-4 text-start">
                  <div>
                    <h4 className="mb-2">Status</h4>
                    <TaskStatusTag status={task.status}/>
                  </div>
                  <div className="mt-2">
                    <h4>Completed at</h4>
                    <div className="mt-2">
                      {task.completed_at ? new Date(task.completed_at + ' UTC').toLocaleString() : 'n/a'}
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4>Output file</h4>
                    {(task.completed_at !== null) ? (
                      <div className="flex flex-row items-center mt-2 cursor-pointer" onClick={() => onResultDownload(task.uuid)} >
                        <ArrowDownTrayIcon className="h-5 w-5 mr-2 fill-[#0D4BEA] hover:scale-105" />
                        <div className="text-blue hover:text-dark-blue">{task.workflow.output.label}</div>
                      </div>
                    ) : (
                      <div className="mt-2">n/a</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-8">
                <h2>Workflow: {task.workflow.name} (v{task.workflow.version})</h2>
                <hr className="my-4 border-1 border-grey-2"/>
                <div>
                  <div>
                    <h4>
                      {task.workflow.review_types.length > 1 ? "Review types" : "Review type"}
                    </h4>
                    {task.workflow.review_types.length !== 0 ? (
                      <div className="mt-2">
                        <ul>
                          {task.workflow.review_types.map((reviewType, idx) => (
                            <li key={idx}
                                className={`inline-block mr-2 py-[3px] px-2 text-sm leading-[17px] rounded-[3px] text-white bg-indigo-500 overflow-hidden`}
                            >
                              {reviewType.label}
                            </li>
                            ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="mt-2">
                        None
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="mt-4">
                      <h4>Output type</h4>
                      <div className="mt-2">
                        <div>
                          {task.workflow.output.label}
                        </div>
                      </div>
                    </div>
                    {!showWorkflowFields && (
                      <div className="flex flex-row items-center mt-2 cursor-pointer" onClick={onShowWorkflowFields}>
                        <div className="mr-1 hover:text-grey-2">
                          Show fields
                        </div>
                        <ChevronDownIcon className="h-5 w-5 mr-2 " />
                      </div>
                    )}
                  </div>
                  {!!showWorkflowFields && (
                    <div className="mt-4">
                      <h4>Fields</h4>
                      {task.workflow.fields.map((field, index) => (
                        <div key={index} className="mt-1">
                          <hr className="my-4 border-1 border-grey-2"/>
                          <div>
                            <div>Name: {field.name} (type: {field.type.label})</div>
                            <div>Output label: {field.output_label}</div>
                            <div>Description: {field.description}</div>
                          </div>
                        </div>
                      ))}
                      <div className="flex flex-row justify-end items-center mt-2 cursor-pointer" onClick={onHideWorkflowFields}>
                        <div className="mr-1 hover:text-grey-2">
                          Hide fields
                        </div>
                        <ChevronUpIcon className="h-5 w-5 mr-2 " />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-8">
                <div className="grid grid-cols-3 gap-[30px] mb-4 rounded-md">
                  <div className="col-span-3">
                    <TaskStatus
                      taskStatuses={subtaskStatuses}
                      title={"Subtasks"}
                      titleSize={"h2"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-start mt-4">
                <div className="flex flex-row px-4">
                  <h4 className="w-1/6">Name</h4>
                  <h4 className="w-1/6">Status</h4>
                  <h4 className="w-1/6">Files</h4>
                </div>
                <hr className="mt-4 border-1 border-grey-2"/>
                {task.subtasks.map((subtask, index) => (
                  <div
                    key={index}
                    className="flex flex-row mt-2 p-2 items-center hover:bg-grey-1 hover:cursor-pointer"
                    onClick={() => openSubtaskModal(subtask)}
                  >
                    <div className="w-1/6">{subtask.public_id}</div>
                    <div className="w-1/6"><SubtaskStatusTag status={subtask.status} /></div>
                    <div className="w-1/6">{subtask.docs.length}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}