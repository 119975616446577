export default class APIService {
  static GetProfile(token) {
    return fetch("/api/profile", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    }).then(
      res => res.json()
    )
  }
  static GetToolsProfile(token) {
    return fetch("/api/tools_profile", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    }).then(
      res => res.json()
    )
  }
  static UpdatePassword(token, data) {
    return fetch("/api/update_password", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static AppLogIn(data) {
    return fetch("/api/app_login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static ToolsLogIn(data) {
    return fetch("/api/tools_login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static LogOut() {
    return fetch("/api/logout", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(
      res => res.json()
    )
  }
  static ParsePDF(token, data) {
    return fetch("/api/parse_pdf", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static GetTaskByUUID(token, taskUUID) {
    return fetch("/api/task/" + taskUUID.toString(), {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static GetTasks(token) {
    return fetch("/api/get_tasks", {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static CreateTask(token, data) {
    return fetch("/api/create_task", {
      method: 'POST',
      headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token,
      },
      body: data
    }).then(
      res => res.json()
    )
  }
  static GetResultsLink(token, data) {
    return fetch("/api/get_results_link", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static GetCurrentShift(token) {
    return fetch("/api/get_current_shift", {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static CreateShift(token) {
    return fetch("/api/create_shift", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static EndShift(token) {
    return fetch("/api/end_shift", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static GetAssignmentByUUID(token, assignmentUUID) {
    return fetch("/api/get_assignment/" + assignmentUUID.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static GetOrCreateAssignment(token) {
    return fetch("/api/get_or_create_assignment", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static AcceptAssignment(token, data) {
    return fetch("/api/accept_assignment", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static DeclineAssignment(token, data) {
    return fetch("/api/decline_assignment", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static GetSubtaskByUUID(token, subtaskUUID) {
    return fetch("/api/subtask/" + subtaskUUID.toString(), {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static GetSubtaskDocumentsByUUID(token, subtaskUUID) {
    return fetch("/api/get_subtask_documents/" + subtaskUUID.toString(), {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static GetSubtaskAndDocs(token, subtaskUUID) {
    return fetch("/api/get_subtask_and_docs/" + subtaskUUID.toString(), {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static GetAssignmentsByShift(token, shiftUUID) {
    return fetch("/api/get_assignments_by_shift/" + shiftUUID.toString(), {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static GetShifts(token) {
    return fetch("/api/get_shifts", {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
  static SubmitReview(token, data) {
    return fetch("/api/submit_review", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static ManuallyUpdateField(token, data) {
    return fetch("/api/manually_update_field", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
  }).then(
    res => res.json()
  )
}
  static ExecuteSubtask(token, data) {
    return fetch("/api/execute_subtask", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static GetLatestWorkflows(token) {
    return fetch("/api/get_latest_workflows", {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(
      res => res.json()
    )
  }
  static CreateOrEditWorkflow(token, data) {
    return fetch("/api/update_workflow", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    }).then(
      res => res.json()
    )
  }
  static GetWorkflowByUUID(token, workflowUUID) {
    return fetch("/api/workflow/" + workflowUUID.toString(), {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    }).then(
      res => res.json()
    )
  }
}