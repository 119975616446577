import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button'


export default function Workflows({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const location = useLocation();

  // const [workflows, setWorkflows] = useState([
  //   {
  //     'uuid': '3212123',
  //     'name': 'Loan extraction',
  //     'last_modified_at': '6/30/2023',
  //     'fields': [
  //       {'name': 'Borrower Name', 'type': {'value': 'name', 'label': 'Name'}, 'description': 'Name of the borrower of this loan', 'output_label': 'borrower_name'},
  //       {'name': 'Interest Rate', 'type': {'value': 'percent', 'label': 'Percent'}, 'description': 'Interest rate of the loan', 'output_label': 'interest_rate', },
  //       {'name': 'Loan Amount', 'type': {'value': 'dollar', 'label': 'Dollar'}, 'description': 'Original loan amount', 'output_label': 'loan_amount', },
  //     ],
  //     'review_types': [{'value': 'all', 'label': 'All'}],
  //     'output': {'value': 'csv', 'label': 'CSV'},
  //   },
  //   {
  //     'uuid': '123123',
  //     'name': 'Title extraction',
  //     'last_modified_at': '6/29/2023',
  //     'fields': [
  //       {'name': 'Current Owner Name', 'type': {'value': 'name', 'label': 'Name'}, 'description': 'Name of the current owner of this property', 'output_label': 'owner_name'},
  //       {'name': 'Property address', 'type': {'value': 'address', 'label': 'Address'}, 'description': 'Full address of this property', 'output_label': 'borrower_name'},
  //     ],
  //     'review_types': [{'value': 'empty', 'label': 'Empty Fields'}, {'value': 'invalid', 'label': 'Invalid Fields'}],
  //     'output': {'value': 'email', 'label': 'Email'},
  //   }
  // ])
  const [workflows, setWorkflows] = useState([])
  const [workflowsLoading, setWorkflowsLoading] = useState(true)

  useEffect(() => {
    APIService.GetLatestWorkflows(token).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setWorkflows(resp.workflows)
      setWorkflowsLoading(false)
    })
  }, [token])

  function onCreateWorkflowClick() {
    onNav('/workflows/create')
  }

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <div className="flex flex-col w-full p-12">
          <div className="flex flex-col text-start">
            <div className="flex flex-row justify-between mb-6 items-center">
              <h1 className="mb-2">Workflows</h1>
              <Button size={BUTTON_SIZES.MEDIUM} color={BUTTON_COLORS.BLUE} onClick={onCreateWorkflowClick} label={"Create workflow"} />
            </div>
            {workflows.length === 0 && !workflowsLoading ? (
            <div className="mt-4">
              <hr className="border-1 border-grey-2"/>
              <div className="flex flex-row mt-4">
                No workflows
              </div>
            </div>
            ) : (
              <div className="flex flex-col text-start mt-6">
                <div className="flex flex-row px-4 font-semibold text-gray-800">
                  <h4 className="w-1/6">Name</h4>
                  <h4 className="w-1/6">Review type</h4>
                  <h4 className="w-1/6">Output</h4>
                  <h4 className="w-1/2">Fields</h4>
                </div>
                <hr className="mt-2 border-1 border-grey-2"/>
                {workflows.map((workflow, index) => (
                  <div>
                    <div className="flex flex-row mt-2 p-4 items-center hover:bg-grey-1 hover:cursor-pointer" onClick={() => onNav('/workflows/' + workflow.uuid)}>
                      <div className="w-1/6 text-gray-800">{workflow.name}</div>
                      <div className="w-1/6 ml-2">
                        <ul>
                          {workflow.review_types.map((reviewType, idx) => (
                            <li key={idx}
                                className={`inline-block mr-2 py-[3px] px-2 text-sm leading-[17px] rounded-[3px] text-white bg-indigo-500 overflow-hidden`}
                            >
                              {reviewType.label}
                            </li>
                            ))}
                        </ul>
                      </div>
                      <div className="w-1/6 text-gray-800">{workflow.output.label}</div>
                      <div className="w-1/2 text-gray-800">
                        {workflow.fields.map((field, index) => (
                          <div className="flex flex-row">
                            <div className="text-sm">{field['name']} (type: {field.type.label}, label: {field.output_label})</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}