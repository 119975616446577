

function dateUtils() {
  return {
    convertTimestampToLocalTime(timestamp) {
      return new Date(timestamp + ' UTC').toLocaleString()
    }
  }
}

export default dateUtils;