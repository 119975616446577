import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ConfirmReviewModal } from '../components/ConfirmReviewModal';
import Dropdown from "../shared/Dropdown";
import SubtaskStatusTag from '../shared/SubtaskStatusTag';
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button';
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import APIService from '../components/APIService';
import '../routes/PDFViewer.css';
import '../index.css';
import HelpText from '../shared/HelpText';



Modal.setAppElement('#root')

export default function SubtaskModal({token, removeToken, saveToken, task, subtask, setSubtask, modalIsOpen, setModalIsOpen}) {
  
  const navigate = useNavigate()
  const [documents, setDocuments] = useState([]); // list of {'name': <name>, 's3_link': <s3_link>}
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show first page by default
  const pdfContainerRef = useRef(null);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [subtaskFields, setSubtaskFields] = useState({});
  const [submitErrors, setSubmitErrors] = useState([]);
  const [showConfirmReviewModal, setShowConfirmReviewModal] = useState(false)
  const [resultBoundingBoxes, setResultBoundingBoxes] = useState([])

  useEffect(() => {
    if (!!subtask) {
      // Example subtask result
      // {'borrower_name': {'result': 'Michael Jones, Mary Stone', 'status': 'manually_updated'}, 'interest_rate': {'result': '3.875%', 'status': 'reviewed'},  ... }

      // If in progress, we will show all the fields we expect from the workflow and have them all as valid and empty.
      if (subtask.status === 'In progress') {
        const expectedFields = {};
        task.workflow.fields.forEach((field, index) => {
          expectedFields[field.output_label] = {'result': '', 'valid': false, 'empty': true, 'name': field.name, 'description': field.description}
        });
        setSubtaskFields(expectedFields)
      } else {
        setSubtaskFields(subtask.result)
      }
      // Fetch the documents
      APIService.GetSubtaskDocumentsByUUID(token, subtask.uuid).then((resp) => {
        if (resp.msg === 'Token has expired') {
          removeToken()
          navigate('/login')
        }
        if (!!resp.access_token) {
          saveToken(resp.access_token)
        }
        setDocuments(resp.documents);
        setSelectedPDF(resp.documents[0]); // Default to the first one
        setPageNumber(1); // Default to page 1
      })
    }
  }, [subtask])

  const handleFieldChange = (fieldName, value) => {
    setSubtaskFields(prevSubtaskFields => ({
      ...prevSubtaskFields,
      [fieldName]: {
        ...prevSubtaskFields[fieldName],
        result: value,
        reviewed: true
      },
    }));
  };

  function onPDFChange(e) {
    // Do not reload if the PDF remains the same
    if (e.s3_link === selectedPDF.s3_link) {
      return;
    }
    for (let i = 0; i< documents.length; i++) {
      if (documents[i].s3_link === e.value) {
        setSelectedPDF(documents[i]);
        setPageNumber(1);
        return;
      }
    }
  }

  // TO FIX: Stopped working after moving it to the React-Modal
  const handleScroll = () => {
    const container = pdfContainerRef.current;
    const pages = container.querySelectorAll('.react-pdf__Page');
    let currentPage = pageNumber;

    // Find the page currently in the viewport
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const rect = page.getBoundingClientRect();
      // console.log('Page: ' + page)
      // console.log('Rectangle Top: ' + rect.top)
      // console.log('Rectangle Bottom: ' + rect.bottom)
      // console.log('Window Height: ' + window.innerHeight)
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        currentPage = parseInt(page.dataset.pageNumber, 10);
        break;
      }
    }
    setPageNumber(currentPage);
  };

  const submit = () => {
    APIService.SubmitReview(token,
      {
        id: subtask.uuid,
        result: subtaskFields,
        override_validation: false
      }
    ).then((resp) => {
      if (resp.errors) {
        setShowConfirmReviewModal(true)
        setSubmitErrors(resp.errors);
        return;
      }
      setSubmitErrors([]);
      closeModal();
    })
  }

  const confirmReview = () => {
    APIService.SubmitReview(token,
      {
        id: subtask.uuid,
        result: subtaskFields,
        override_validation: true
      }
    ).then((resp) => {
      setShowConfirmReviewModal(false);
      setSubmitErrors([]);
      closeModal();
    })
  }

  function closeModal() {
    setModalIsOpen(false);
    setSubtask(null);
  }

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={
        {
          content: {
            left: '25%',
            width: '75%',
            height: '100%',
            top: '-0px',
            boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 10px'
          }
        }
      }
    >
      {subtask && (
        <div className="grow px-6 py-4">
          <div className='flex flex-row justify-between items-center'>
            <div className="flex flex-row gap-3 items-center">
              <h1> Subtask: {subtask.public_id}</h1>
              <SubtaskStatusTag status={subtask.status}/>
            </div>
            <XMarkIcon
              className="h-5 w-5 text-gray-500 cursor-pointer hover:text-grey-2"
              onClick={closeModal}
            />
          </div>
          <hr className="my-4 border-1 border-grey-2"/>
          <div className='flex flex-row'>
            {showConfirmReviewModal ? <ConfirmReviewModal token={token} removeToken={removeToken} saveToken={saveToken} submitErrors={submitErrors} setSubmitErrors={setSubmitErrors} setShowModal={setShowConfirmReviewModal} onSubmit={confirmReview} /> : null}
            <div className="flex flex-row w-full">
              <div className="w-2/3">
                {selectedPDF && (
                  <div>
                    <h3>Documents ({documents.length})</h3>
                    <div className="mt-2 w-2/3 h-12 relative">
                      <Dropdown
                        options={documents.map((doc, index) => { return {value: doc.s3_link, label: doc.name}})}
                        onChange={(e) => onPDFChange(e)}
                        value={selectedPDF.name}
                      />           
                    </div>
                    <div className="mt-2 pdf-container border-2 border-gray-400 rounded-sm" ref={pdfContainerRef} onWheel={handleScroll}>
                      <Document
                        file={selectedPDF.s3_link}
                        options={{ workerSrc: '/pdf.worker.js' }}
                        onLoadSuccess={async (pdf) => {
                          setNumPages(pdf.numPages);
                          var boundingBoxes = []
                          const page = await pdf.getPage(1)
                          const pageWidth = page.view[2]
                          for (let field in subtaskFields) {
                            const value = subtaskFields[field];
                            if (value['source'] && value['source']['bounding_box']) {
                              const sourceDimensions = value['source']['source_file_dimensions']
                              const sourcePage = value['source']['source_file_page']
                              const file = value['source']['file']
                              const bb = value['source']['bounding_box']
                              boundingBoxes.push({
                                'top': Math.round(bb[1] * pageWidth / sourceDimensions[0]),
                                'left': Math.round(bb[0] * pageWidth / sourceDimensions[0]),
                                'width': Math.round((bb[2] - bb[0]) * pageWidth / sourceDimensions[0]),
                                'height': Math.round((bb[3] - bb[1]) * pageWidth / sourceDimensions[0]),
                                'page': sourcePage,
                                'file': file,
                              })
                            }
                          }
                          setResultBoundingBoxes(boundingBoxes)
                        }}
                        onItemClick={({ pageNumber }) => setPageNumber(pageNumber)}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page key={`page_${index + 1}`} pageNumber={index + 1} className="pdf-page relative">
                            {resultBoundingBoxes.map((bb, _) => index === (bb.page - 1) && (selectedPDF.s3_link.includes(bb.file)) && (
                              <div className={`absolute top-[${bb.top}px] left-[${bb.left}px] w-[${bb.width}px] h-[${bb.height}px] bg-opacity-50 bg-red-400`}>
                                {' '}
                              </div>
                            ))}
                          </Page>
                        ))}
                      </Document>
                    </div>
                    {/* <div>
                      <p className="mt-4 text-center">Page {pageNumber} of {numPages}</p>
                    </div> */}
                  </div>
                )}
              </div>
              <div className='w-1/3 ml-12'>
                <h3>Fields</h3>
                {Object.keys(subtaskFields).map((fieldName, index) => (
                  <div className="flex flex-col mt-3" key={index}>
                    <div className="flex flex-row gap-1 items-center">
                      <h4 className="text-sm">{subtaskFields[fieldName].name}</h4>
                      <HelpText text={subtaskFields[fieldName].description} />
                    </div>
                    <div className="flex flex-row mt-1">
                      <input
                        className="pl-2 py-1 text-sm border-2 border-[#DFDFDF] rounded-md w-full"
                        type="text"
                        id={fieldName}
                        value={subtaskFields[fieldName].result}
                        onChange={e => handleFieldChange(fieldName, e.target.value)}
                      />
                      {/* {subtaskFields[fieldName].valid ? (
                        <CheckCircleIcon className="h-5 w-5 ml-2 mt-1 opacity-60 text-[#1AD2A6]" title="valid" />
                      ) : (
                        <XCircleIcon className="h-5 w-5 ml-2 mt-1 text-red-300" title="invalid" />
                      )} */}
                    </div>
                  </div>
                ))}
                <div className='mt-4'>
                  <Button size={BUTTON_SIZES.MEDIUM} color={BUTTON_COLORS.BLUE} onClick={submit} label={"Submit fields"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}