import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';


export default function HelpText({
    text
  }) {

    const [visible, setVisible] = useState(false)

    return (
      <div className="relative">
        <InformationCircleIcon className="h-5 w-5" onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} />
        {!!visible && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 p-2">
            <div className="w-64 bg-white rounded-[4px] shadow-md border border-grey-1">
              <div className="px-4 pt-4 text-sm my-auto text-center">
                <p>{text}</p>
              </div>
            <div className="bg-white w-5 h-5 transform rotate-45 mx-auto translate-y-1/4"></div>
          </div>
        </div>
        )}
      </div>
    )
  }