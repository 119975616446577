import React from "react";
import * as OutlineHeroIcon from '@heroicons/react/outline';

function snakeCaseToPascalCase(snakeCase) {
  if(!snakeCase) {
    return '';
  }
  return snakeCase.split('-').map(str => str[0].toUpperCase() + str.slice(1)).join('');
}

function getHeroIcon(iconName) {
  const pascalCaseIconName = snakeCaseToPascalCase(iconName);
  if (!pascalCaseIconName) return null;
  return OutlineHeroIcon[pascalCaseIconName + 'Icon'];
}

export const BUTTON_SIZES = Object.freeze({
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
  XSMALL: 'XSMALL',
  RELATIVE: 'RELATIVE',
});

export const BUTTON_COLORS = Object.freeze({
  GREEN: 'GREEN',
  ORANGE: 'ORANGE',
  BLUE: 'BLUE',
  GREY: 'GREY',
  SECONDARY_BLUE: 'SECONDARY_BLUE',
  SECONDARY_ORANGE: 'SECONDARY_ORANGE'
});

const BUTTON_PADDING_CLASS = Object.freeze({
  [BUTTON_SIZES.LARGE]: 'py-3 px-[56px]',
  [BUTTON_SIZES.MEDIUM]: 'py-1.5 px-3',
  [BUTTON_SIZES.SMALL]: 'py-0.5 px-2',
  [BUTTON_SIZES.XSMALL]: 'py-0.5 px-1',
  [BUTTON_SIZES.RELATIVE]: 'py-1.5 px-3',
});

const BUTTON_COLOR_CLASS = Object.freeze({
  [BUTTON_COLORS.GREEN]: 'text-white border-2 border-green bg-green',
  [BUTTON_COLORS.ORANGE]: 'text-white border-2 border-orange bg-orange',
  [BUTTON_COLORS.BLUE]: 'text-white border-2 border-blue bg-blue',
  [BUTTON_COLORS.GREY]: 'text-white border-2 border-grey-2 bg-grey-2',
  [BUTTON_COLORS.SECONDARY_BLUE]: 'text-blue border-2 border-blue',
  [BUTTON_COLORS.SECONDARY_ORANGE]: 'text-orange border-2 border-orange',

});

const BUTTON_TYPOGRAPHY_CLASS = Object.freeze({
  [BUTTON_SIZES.LARGE]: 'font-bold text-[18px] leading-[22px]',
  [BUTTON_SIZES.MEDIUM]: 'text-base',
  [BUTTON_SIZES.SMALL]: 'text-base',
  [BUTTON_SIZES.XSMALL]: 'text-sm',
  [BUTTON_SIZES.RELATIVE]: 'text-base',
});

const ICON_SIZE_CLASS = Object.freeze({
  [BUTTON_SIZES.LARGE]: 'w-[18px] h-[18px]',
  [BUTTON_SIZES.MEDIUM]: 'w-4 h-4',
  [BUTTON_SIZES.SMALL]: 'w-3.5 h-3.5',
  [BUTTON_SIZES.XSMALL]: 'w-3 h-3',
  [BUTTON_SIZES.RELATIVE]: 'w-4 h-4',

});

export default function Button({
  size=BUTTON_SIZES.MEDIUM,
  color=BUTTON_COLORS.BLUE,
  startIconName,
  endIconName,
  disabled,
  onClick,
  label
}) {
  let buttonClass = 'justify-center items-center gap-[6px] rounded-[4px] disabled:opacity-20 disabled:cursor-not-allowed';
  if (color === BUTTON_COLORS.GREEN) {
    buttonClass += ' hover:bg-dark-green hover:border-dark-green';
  }
  if (color === BUTTON_COLORS.ORANGE) {
    buttonClass += ' hover:bg-dark-orange hover:border-dark-orange';
  }
  if (color === BUTTON_COLORS.BLUE) {
    buttonClass += ' hover:bg-dark-blue hover:border-dark-blue';
  }
  if (color === BUTTON_COLORS.GREY) {
    buttonClass += ' hover:bg-dark-grey hover:border-dark-grey';
  }
  if (color == BUTTON_COLORS.SECONDARY_BLUE) {
    buttonClass += ' hover:text-dark-blue hover:border-dark-blue';
  }
  if (color == BUTTON_COLORS.SECONDARY_ORANGE) {
    buttonClass += ' hover:text-dark-orange hover:border-dark-orange';
  }
  buttonClass += ' ' + BUTTON_PADDING_CLASS[size];
  buttonClass += ' ' + BUTTON_COLOR_CLASS[color];
  buttonClass += ' ' + BUTTON_TYPOGRAPHY_CLASS[size];
  const StartIcon = getHeroIcon(startIconName);
  const EndIcon = getHeroIcon(endIconName);

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={buttonClass}
    >
      {StartIcon && <StartIcon className={`text-current ${ICON_SIZE_CLASS[size]}`} strokeWidth={2} />}
      {label}
      {EndIcon && <EndIcon className={`text-current ${ICON_SIZE_CLASS[size]}`} strokeWidth={2} />}
    </button>
  )
}