import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import APIService from '../../components/APIService'
import { CheckBadgeIcon } from '@heroicons/react/20/solid'
import { Oval } from 'react-loader-spinner'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../../shared/Button'
import TextInput from '../../shared/TextInput'
import ToolsSidebar from '../../components/ToolsSidebar'


export default function Settings({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const [profile, setProfile] = useState({})
  const [showPasswordElement, setShowPasswordElement] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [updatePasswordError, setUpdatePasswordError] = useState('')
  const [updatingProfile, setUpdatingProfile] = useState(false)

  useEffect(() => {
    APIService.GetToolsProfile(token)
      .then(resp => {
        if (resp.msg === 'Token has expired') {
          removeToken();
          navigate("/login");
        }
        if (!!resp.access_token) {
          saveToken(resp.access_token);
        }
        setProfile(resp);
      })
      .catch(error => console.log(error));
  }, [token]);

  function onPasswordChangeClick() {
    setShowPasswordElement(true)
  }

  function onPasswordUpdate() {
    setUpdatingProfile(true)
    APIService.UpdatePassword(token, { currentPassword: currentPassword, newPassword: newPassword }).then(resp => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      if (!!resp.error) {
        setNewPassword('')
        setCurrentPassword('')
        setUpdatePasswordError(resp.error)
      } else {
        setNewPassword('')
        setCurrentPassword('')
        setUpdatePasswordError('')
        setShowPasswordElement(false)
      }
      setUpdatingProfile(false)
    })
    .catch(error => console.log(error));
  }

  function onCurrentPasswordChange(e) {
    setCurrentPassword(e.target.value)
  }

  function onNewPasswordChange(e) {
    setNewPassword(e.target.value)
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <ToolsSidebar removeToken={removeToken} />
        <div className="flex flex-col w-full">
          <div className="flex flex-col text-start">
            <div className="flex flex-row mt-12 ml-6">
              <h1>Settings</h1>
            </div>
            <hr className="mt-4 border-1 border-grey-1"></hr>

            <div className="flex flex-col mx-6">
              <div className="mt-12">
                <h4> Email </h4>
                <div className="flex flex-row items-center mt-2">
                  <div className="text-gray-2">
                    {profile.email}
                  </div>
                  {!!profile.email_verified && (
                    <div className="ml-2">
                      <CheckBadgeIcon className="h-5 w-5 mr-2" title="verified"/>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <h4> Role </h4>
                <div className="text-gray-2 mt-2">
                    {profile.role}
                </div>
              </div>
              <div className="mt-4">
                <h4> Last login </h4>
                <div className="text-gray-2 mt-2">
                  {profile.last_login_time !== '' ? new Date(profile.last_login_time + ' UTC').toLocaleString() : ''}
                </div>
              </div>
              {!!showPasswordElement ? (
                <div className="mt-4">
                  <hr />
                  <div className="w-1/4 mt-4">
                    <TextInput onChange={onCurrentPasswordChange} value={currentPassword} type={"password"} title={"Current password"} error={updatePasswordError !== '' ? ' ' : ''}/>
                  </div>
                  <div className="w-1/4 mt-4">
                    <TextInput onChange={onNewPasswordChange} value={newPassword} type={"password"} title={"New password"} error={updatePasswordError}/>
                  </div>
                  <div className="flex flex-row items-center h-12 mt-4">
                    <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.SECONDARY_BLUE} onClick={onPasswordUpdate} disabled={!!updatingProfile || currentPassword === '' || newPassword === ''} label={"Update password"} />
                    <Oval className="ml-2" visible={!!updatingProfile} width={36} height={36} strokeWidth={5} color="#0D4BEA" secondaryColor="#0D4BEA"/>
                  </div>
                </div>
              ) : (
                <div className="mt-4">
                  <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.SECONDARY_BLUE} onClick={onPasswordChangeClick} label={"Change password"} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}