import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import format from 'date-fns/format'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'
import TaskStatusTag from '../shared/TaskStatusTag'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button'
import TaskStatus from '../components/TaskStatus';


export default function Tasks({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const location = useLocation();

  const [tasks, setTasks] = useState([])
  const [tasksLoading, setTasksLoading] = useState(true)
  const [taskStatusCounts, setTaskStatusCounts] = useState({})

  function taskStatusCounter(tasks) {
    var res = tasks.reduce(function(obj, v) {
      // increment or set the property
      // `(obj[v.status] || 0)` returns the property value if defined
      // or 0 ( since `undefined` is a falsy value
      obj[v.status] = (obj[v.status] || 0) + 1;
      // return the updated object
      return obj;
      // set the initial value as an object
    }, {})
    return res
  }

  useEffect(() => {
    APIService.GetTasks(token).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setTasks(resp.tasks);
      setTaskStatusCounts(taskStatusCounter(resp.tasks));
      setTasksLoading(false);
    })
  }, [token])

  function onResultsDownload(e, taskUUID) {
    e.stopPropagation()
    APIService.GetResultsLink(token, {taskUUID}).then(resp => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      const link = document.createElement('a');
      link.href = resp.presigned_url;
      link.setAttribute('download', `${taskUUID}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => console.log(error));
  }

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <div className="flex flex-col w-full p-12">
          <div className="grid grid-cols-3 gap-[30px] mb-4 rounded-md">
            <div className="col-span-3">
              <TaskStatus
                taskStatuses={taskStatusCounts}
                title={"Tasks"}
                titleSize={"h1"}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <h1 className=""></h1>
            <Button size={BUTTON_SIZES.MEDIUM} color={BUTTON_COLORS.BLUE} onClick={() => onNav("/tasks/create")} label={"Create task"} />
          </div>
          {tasks.length === 0 && !tasksLoading ? (
            <div className="mt-4">
              <hr className="border-1 border-grey-2"/>
              <div className="flex flex-row mt-4">
                No tasks
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-start mt-6">
              <div className="flex flex-row px-4">
                <h4 className="w-1/6 font-semibold">Submitted</h4>
                <h4 className="w-1/6 font-semibold">Name</h4>
                <h4 className="w-1/6 font-semibold">Status</h4>
                <h4 className="w-1/6 font-semibold">Workflow</h4>
                <h4 className="w-1/6 font-semibold">Subtasks</h4>
                <h4 className="w-1/6 font-semibold">Output file</h4>
              </div>
              <hr className="mt-2 border-1 border-grey-2"/>
              {tasks.map((task, index) => (
                <div className={"flex flex-row mt-4 p-4 items-center hover:bg-grey-1 hover:cursor-pointer"} onClick={() => onNav('/tasks/' + task.uuid)}>
                  <div className="w-1/6">{format(new Date(task.created_at), 'MM/dd/yyyy')}</div>
                  <div className="w-1/6">{task.name}</div>
                  <div className="w-1/6"><TaskStatusTag status={task.status} /></div>
                  <div className="w-1/6">{task.workflow_name}</div>
                  <div className="w-1/6">{task.subtasks.length}</div>
                  {(task.completed_at !== null) ? (
                    <div className="flex flex-row items-center w-1/6" onClick={(e) => onResultsDownload(e, task.uuid)}>
                      <ArrowDownTrayIcon className="h-5 w-5 mr-2 cursor-pointer fill-[#0D4BEA] hover:scale-105" />
                      <div className="text-blue hover:text-dark-blue">{task.workflow.output.label}</div>
                    </div>
                  ) : (
                    <div className="w-1/6"></div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}