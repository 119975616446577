import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import APIService from '../../components/APIService'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../../shared/Button'
import ToolsSidebar from '../../components/ToolsSidebar'
import CookieCrumbNav from '../../components/CookieCrumbNav'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HelpText from '../../shared/HelpText';
import Dropdown from "../../shared/Dropdown";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Oval } from 'react-loader-spinner';
import ProgressBar  from '../../components/ProgressBar';
import dateUtils from '../../utils/DateUtils';
import currencyUtils from '../../utils/CurrencyUtils';


export default function Tasks({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const location = useLocation()
  const { convertTimestampToLocalTime } = dateUtils()
  const { formatCurrency } = currencyUtils()

  const [shift, setShift] = useState(true)
  const [assignments, setAssignments] = useState([])
  const [completedAssignments, setCompletedAssignments] = useState([])
  const [activeAssignment, setActiveAssignment] = useState(null)
  const [loadingData, setLoadingData] = useState(true)
  const [createShiftError, setCreateShiftError] = useState(null)
  const [endShiftError, setEndShiftError] = useState(null)
  const [acceptTaskError, setAcceptTaskError] = useState(null)
  const [acceptingTask, setAcceptingTask] = useState(false)  // we have clicked accept task and are waiting for the backend

  useEffect(() => {
    APIService.GetCurrentShift(token).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setShift(resp.shift)
      if (resp.shift) {
        APIService.GetAssignmentsByShift(token, resp.shift.uuid).then((resp) => {
          if (resp.msg === 'Token has expired') {
            removeToken()
            navigate('/login')
          }
          if (!!resp.access_token) {
            saveToken(resp.access_token)
          }
          setAssignments(resp.assignments)

          const inProgressAssignment = resp.assignments.filter(a => (a.completed_at === null && a.declined_at === null))
          setActiveAssignment(inProgressAssignment.length !== 0 ? inProgressAssignment[0] : null)
          setCompletedAssignments(resp.assignments.filter(a => a.completed_at !== null))
          setLoadingData(false)
        })
      } else {
        setLoadingData(false)
      }
    })
  }, [token])

  // polls the backend every N seconds until we have an active assignment for this reviewer
  useEffect(() => {
    const interval = setInterval(() => {
      if (activeAssignment === null) {
        APIService.GetOrCreateAssignment(token).then((resp) => {
          if (resp.msg === 'Token has expired') {
            removeToken()
            navigate('/login')
          }
          if (!!resp.access_token) {
            saveToken(resp.access_token)
          }
          setActiveAssignment(resp.assignment)
        })
      }
    }, 10 * 1000) // every 10 seconds
  
    return () => clearInterval(interval);
  }, [token, activeAssignment])

  function onStartShift() {
    APIService.CreateShift(token).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      if (!!resp.error) {
        setCreateShiftError(resp.error)
      } else {
        setShift(resp.shift)
        setCreateShiftError(null)
      }
    })
  }

  function onEndShift() {
    APIService.EndShift(token).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      if (!!resp.error) {
        setEndShiftError(resp.error)
      } else {
        setShift(resp.shift)
        setEndShiftError(null)
        setAssignments([])
        setCompletedAssignments([])
        setActiveAssignment(null)
      }
    })
  }
  
  function onAcceptTask() {
    setAcceptingTask(true)
    APIService.AcceptAssignment(token, {assignmentUUID: activeAssignment.uuid}).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      if (!!resp.error) {
        setAcceptTaskError(resp.error)
      } else {
        onNav('/tasks/' + resp.assignment.uuid)
        setAcceptTaskError(null)
      }
      setAcceptingTask(false)
    })
  }

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <ToolsSidebar removeToken={removeToken} />
        {!!loadingData ? (
          <div className="flex flex-col py-6 w-full">
            <div className="my-auto mx-auto">
              Loading ...
            </div>
          </div>
        ) : (
          <div className="flex flex-col py-6 w-full">
            <div className="mx-6">
              <div className="flex flex-row justify-between mt-6">
                <div>
                  <h1>Tasks</h1>
                </div>
              </div>
            </div>
            <hr className="mt-4 border-1 border-grey-1"/>
            {!!shift ? (
              <div>
                <div className="flex flex-row mt-12 w-full">
                  {(!activeAssignment || activeAssignment.accepted_at === null)  ? (
                    <div className="w-1/2 border border-grey-1 rounded-md ml-12 mr-6">
                      <div className="m-4 text-start">
                        {!activeAssignment ? (
                          <div className="mt-4">
                            <h4 className="mt-4">Assigning task</h4>
                            <Oval width={36} height={36} strokeWidth={5} color="#0D4BEA" secondaryColor="#0D4BEA"/>
                          </div>
                        ): (
                          <div>
                            <h4 className="mt-4">Assigning task</h4>
                            <div className='flex flex-row items-center justify-between mt-4'>
                              <div>
                                <h2>Task ID: {activeAssignment.uuid.slice(-6)}</h2>
                                <div>Subtasks: {activeAssignment.num_subtasks}</div>
                                <div>Payout: {formatCurrency(activeAssignment.monetary_fields)}</div>
                              </div>
                              <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.Blue} label={'Accept task'} onClick={onAcceptTask} disabled={!!acceptingTask}/>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="w-1/2 border border-grey-1 rounded-md ml-12 mr-6 hover:bg-grey-1 hover:cursor-pointer" onClick={() => onNav('/tasks/' + activeAssignment.uuid)}>
                      <div className="m-4 text-start">
                        <h4>In progress task</h4>
                          <div>
                            <div className="mt-4">Task ID: {activeAssignment.uuid.slice(-6)}</div>
                            <h2 className="mt-4 mb-2">{activeAssignment.num_completed_subtasks}/{activeAssignment.num_subtasks} subtasks complete</h2>
                            <ProgressBar statuses={['complete', 'incomplete']} statusCounts={[activeAssignment.num_completed_subtasks, activeAssignment.num_subtasks - activeAssignment.num_completed_subtasks]} statusColors={['blue', 'grey-2']} />
                          </div>
                      </div>
                    </div>
                  )}
                  <div className="w-1/2 border border-grey-1 rounded-md mr-12 ml-6">
                    <div className="m-4 text-start">
                      <div className="flex flex-row justify-between items-center">
                        <h4>Current Shift</h4>
                        <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.SECONDARY_ORANGE} label={'End shift'} onClick={onEndShift} disabled={!!activeAssignment && activeAssignment.accepted_at && activeAssignment.completed_at === null}/>
                      </div>
                      <div className="mt-4">Start time: {convertTimestampToLocalTime(shift.start_time)}</div>
                      {completedAssignments.length !== 1 ? (
                        <div>Completed: {completedAssignments.length} tasks</div>
                      ) : (
                        <div>Completed: {completedAssignments.length} task</div>
                      )}
                      {completedAssignments.length !== 0 ? (
                        <div>Total pay: {formatCurrency({'amount': completedAssignments.reduce((partialSum, a) => partialSum + a.monetary_fields.amount, 0), 'currency': completedAssignments[0].monetary_fields.currency})}</div>
                      ) : (
                        <div>Total pay: $0</div>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="mt-8 border-1 border-grey-1"/>
                <div className="flex flex-row">
                  <div className="w-full text-start">
                    <div className='w-full flex flex-row'>
                      <div className="w-1/4">
                        <h4 className='my-1 px-6'>Completed tasks</h4>
                      </div>
                      <div className="w-1/4">
                        <h4 className='my-1 px-6'>Subtasks</h4>
                      </div>
                      <div className="w-1/4">
                        <h4 className='my-1 px-6'>Completed At</h4>
                      </div>
                      <div className="w-1/4">
                        <h4 className='my-1 px-6'>Payout</h4>
                      </div>
                    </div>
                    <hr className="border-1 border-grey-1"/>
                    {!!completedAssignments && (
                      <div>
                      {completedAssignments.map((assignment, index) => (
                        <div className='w-full flex flex-row py-2'>
                          <div className="w-1/4">
                            <div className='my-1 px-6'>{assignment.uuid.slice(-6)}</div>
                          </div>
                          <div className="w-1/4">
                            <div className='my-1 px-6'>{assignment.num_subtasks}</div>
                          </div>
                          <div className="w-1/4">
                            <div className='my-1 px-6'>{convertTimestampToLocalTime(assignment.completed_at)}</div>
                          </div>
                          <div className="w-1/4">
                            <div className='my-1 px-6'>{formatCurrency(assignment.monetary_fields)}</div>
                          </div>
                        </div>
                      ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="my-auto">
                <h2>Welcome back!</h2>
                <div className="flex flex-row items-center justify-center mt-6">
                  <div>
                    <Button size={BUTTON_SIZES.MEDIUM} color={BUTTON_COLORS.BLUE} label={'Start shift'} onClick={onStartShift}/>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}