import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import APIService from '../../components/APIService'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../../shared/Button'
import ToolsSidebar from '../../components/ToolsSidebar'
import CookieCrumbNav from '../../components/CookieCrumbNav'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HelpText from '../../shared/HelpText';
import Dropdown from "../../shared/Dropdown";
import { ChevronLeftIcon, ChevronRightIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { Oval } from 'react-loader-spinner'
import SubtaskStatusTag from '../../shared/SubtaskStatusTag';


export default function Task({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const { assignmentUUID } = useParams()
  const pdfContainerRef = useRef(null)
  const inputRef = useRef(null)
  const location = useLocation()

  const [loadingData, setLoadingData] = useState(true)  // initial data is loading
  const [assignment, setAssignment] = useState(null)
  const [subtask, setSubtask] = useState(null)
  const [results, setResults] = useState({})
  const [documents, setDocuments] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(1)
  const [selectedPDF, setSelectedPDF] = useState(null)
  const [resultsUpdated, setResultsUpdated] = useState(false)  // results were manually updated, activates save button
  const [savingResults, setSavingResults] = useState(false)
  const [submitErrors, setSubmitErrors] = useState([])
  const [activeReviewField, setActiveReviewField] = useState(null)
  const [validationError, setValidationError] = useState(null)

  const cookieCrumbNavPaths = [
    {
        'name': 'Tasks',
        'location': '/tasks',
    }
  ]

  useEffect(() => {
    APIService.GetAssignmentByUUID(token, assignmentUUID).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setAssignment(resp.assignment)
      if (resp.assignment) {
        getSubtaskAndDocs(resp.assignment.subtask_uuids[0])
      } else {
        setLoadingData(false)
      }
    })
  }, [token])


  const getSubtaskAndDocs = (subtaskUUID) => {
    setLoadingData(true)
    APIService.GetSubtaskAndDocs(token, subtaskUUID).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setSubtask(resp.subtask)
      setResults(resp.subtask.result)
      setDocuments(resp.documents)
      setSelectedPDF(resp.documents[0])
      setPageNumber(1)
      setActiveReviewField(null)
      setValidationError(null)
      setLoadingData(false)
    })
  }

  const onPreviousSubtaskClick = () => {
    const currentSubtaskIdx = assignment.subtask_uuids.indexOf(subtask.uuid)
    if (currentSubtaskIdx > 0) {
      const previousSubtaskUUID = assignment.subtask_uuids[currentSubtaskIdx - 1]
      getSubtaskAndDocs(previousSubtaskUUID)
    }
  }

  const onNextSubtaskClick = () => {
    const currentSubtaskIdx = assignment.subtask_uuids.indexOf(subtask.uuid)
    if (currentSubtaskIdx < assignment.subtask_uuids.length - 1) {
      const nextSubtaskUUID = assignment.subtask_uuids[currentSubtaskIdx + 1]
      getSubtaskAndDocs(nextSubtaskUUID)
    }
  }

  const handleFieldChange = (fieldName, value) => {
    setResults(prevSubtaskFields => ({
      ...prevSubtaskFields,
      [fieldName]: {
        ...prevSubtaskFields[fieldName],
        result: value,
      },
    }));
    setResultsUpdated(true)
  };

  const saveManuallyUpdatedField = (fieldName, action) => {
    setSavingResults(true)
    APIService.ManuallyUpdateField(token,
      {
        uuid: subtask.uuid,
        fieldOutputLabel: fieldName,
        fieldValue: results[fieldName],
        action: action, // approve or update
      }
    ).then((resp) => {
      setSavingResults(false)
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      if (resp.errors) {
        setSubmitErrors(resp.errors);
        return;
      }
      if (resp.validation_error) {
        setValidationError({field: fieldName, error: resp.validation_error})
        return;
      }
      setValidationError(null)
      // check if we are done with this assignment
      APIService.GetAssignmentByUUID(token, assignmentUUID).then((resp) => {
        if (resp.msg === 'Token has expired') {
          removeToken()
          navigate('/login')
        }
        if (!!resp.access_token) {
          saveToken(resp.access_token)
        }
        if (resp.assignment) {
          if (resp.assignment.completed_at) {
            onNav('/tasks');
          }
        }
      })
      setSubmitErrors([]);
      setSubtask(resp.subtask)
      setResults(resp.subtask.result)
      setResultsUpdated(false)
    })
  }

  const handleScroll = () => {
    const container = pdfContainerRef.current;
    const pages = container.querySelectorAll('.react-pdf__Page');
    let currentPage = pageNumber;
    // Find the page currently in the viewport
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const rect = page.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        currentPage = parseInt(page.dataset.pageNumber, 10);
        break;
      }
    }
    setPageNumber(currentPage);
  }

  function onPDFChange(e) {
    // Do not reload if the PDF remains the same
    if (e.s3_link === selectedPDF.s3_link) {
      return;
    }
    for (let i = 0; i< documents.length; i++) {
      if (documents[i].s3_link === e.value) {
        setSelectedPDF(documents[i]);
        setPageNumber(1);
        return;
      }
    }
  }

  const jumpPage = (pageNumber) => {
    const targetPage = pdfContainerRef.current.querySelector(
      `.react-pdf__Page[data-page-number="${pageNumber}"]`
    );
    const firstPage = pdfContainerRef.current.querySelector(
      `.react-pdf__Page[data-page-number="1"]`
    );
    const pdfCanvas = document.querySelector('#current-pdf-document')
    if (targetPage && firstPage) {
      pdfCanvas.scroll({top: targetPage.offsetTop - firstPage.offsetTop, behavior: 'smooth', block: "start"});
    }
  }

  const handleJumpToPage = (e) => {
    e.preventDefault();
    const value = inputRef.current.value;
    const parsedPageNumber = parseInt(value, 10);
    const validPageNumber = Math.min(
      Math.max(parsedPageNumber, 1),
      numPages || 1
    );
    setPageNumber(validPageNumber);
    const targetPage = pdfContainerRef.current.querySelector(
      `.react-pdf__Page[data-page-number="${validPageNumber}"]`
    );
    const firstPage = pdfContainerRef.current.querySelector(
      `.react-pdf__Page[data-page-number="1"]`
    );
    const pdfCanvas = document.querySelector('#current-pdf-document')
    if (targetPage && firstPage) {
      pdfCanvas.scroll({top: targetPage.offsetTop - firstPage.offsetTop, behavior: 'smooth', block: "start"});
    }
  };

  const showSourceDocument = (fieldName) => {
    setActiveReviewField(fieldName)
    if (results[fieldName]['source']) {
      const sourceDocName = results[fieldName]['source']['file'].split('/').slice(-1)[0]
      const sourcePage = results[fieldName]['source']['source_file_page']
      for (var i = 0; i < documents.length; i ++) {
        if (documents[i]['name'] === sourceDocName) {
          setSelectedPDF(documents[i])
          setPageNumber(sourcePage)
          jumpPage(sourcePage)
          break
        }
      }
    }
  }

  const onPreviousPageClick = () => {
    if (pageNumber === 1) {
      return
    }
    const prevPage = pageNumber - 1;
    setPageNumber(prevPage)
    jumpPage(prevPage)
  }

  const onNextPageClick = () => {
    if (pageNumber === numPages) {
      return
    }
    const nextPage = pageNumber + 1;
    setPageNumber(nextPage)
    jumpPage(nextPage)
  }

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <ToolsSidebar removeToken={removeToken} />
        {!loadingData ? (
          <div className="flex flex-col py-6 w-full">
            <div className="mx-6">
              <CookieCrumbNav paths={cookieCrumbNavPaths} currentPathName={"Task ID: " + assignmentUUID.slice(-6)} />
              <div className="flex flex-row justify-between mt-6">
                <div className="flex flex-row items-center">
                  <h1 className="mr-2">Subtask ID: {subtask.uuid.slice(-6)}</h1>
                  <SubtaskStatusTag status={subtask.status}/>
                </div>
                <div className="flex flex-row items-center">
                  <div className="mr-4">
                    Subtask {assignment.subtask_uuids.indexOf(subtask.uuid) + 1} of {assignment.subtask_uuids.length}
                  </div>
                  <div className="mr-4">
                    <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.SECONDARY_BLUE} label={"Previous"} onClick={onPreviousSubtaskClick} disabled={assignment.subtask_uuids.indexOf(subtask.uuid) === 0}/>
                  </div>
                  <div>
                    <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.BLUE} label={"Next"} onClick={onNextSubtaskClick} disabled={assignment.subtask_uuids.indexOf(subtask.uuid) === (assignment.subtask_uuids.length - 1)}/>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-4 border-1 border-grey-1"/>
            <div className="flex flex-row">
              <div className="w-3/5 text-start">
                <div className='w-full flex flex-row'>
                  <div className="w-1/4">
                    <h4 className='my-1 px-2'>Field Name</h4>
                  </div>
                  <div className="w-1/4 border-l border-grey-1">
                    <h4 className='my-1 px-2'>Value</h4>
                  </div>
                  <div className="w-1/4 border-l border-grey-1">
                    <h4 className='my-1 px-2'>Source</h4>
                  </div>
                  <div className="w-1/4 border-l border-grey-1">
                    <h4 className='my-1 px-2'>Status</h4>
                  </div>
                </div>
                <hr className="border-1 border-grey-1"/>
                {!loadingData && (
                  <div>
                  {Object.keys(results).map((fieldName, index) => (
                    <div>
                      <div className={'w-full flex flex-row text-sm ' + (results[fieldName]['source'] ? 'hover:bg-grey-1 hover:cursor-pointer' : '') + (((results[fieldName].status === 'invalid') || (results[fieldName].status === 'empty') || (results[fieldName].status === 'not_found')) ? ' bg-red-100 hover:bg-red-200' : '')} onClick={() => showSourceDocument(fieldName)}>
                        <div className="w-1/4 flex flex-row items-center px-2">
                          <div className='my-1 mr-1'>{results[fieldName].name}</div>
                          <HelpText text={results[fieldName].description} />
                        </div>
                        <div className="flex flex-col items-center w-1/4 border-l border-grey-1">
                          <div className="w-full pt-1">
                            <textarea
                              className="px-2 pt-1 w-full"
                              type="text"
                              id={fieldName}
                              disabled={fieldName !== activeReviewField}
                              value={results[fieldName].result}
                              onChange={e => handleFieldChange(fieldName, e.target.value)}
                              onClick={(e) => {e.stopPropagation()}}  // dont default show source document if we are just editing the field value
                            />
                            {(fieldName === activeReviewField) && (!!validationError && validationError.field === fieldName) && (
                              <div className="text-orange px-1 pb-1">
                                {validationError.error}
                              </div>
                            )}
                            {(fieldName === activeReviewField) && (!!resultsUpdated) && (
                              <div className="flex justify-end mb-1">
                                <Button size={BUTTON_SIZES.XSMALL} color={BUTTON_COLORS.SECONDARY_BLUE} onClick={() => saveManuallyUpdatedField(fieldName, 'update')} label={'Update'}/>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row items-center w-1/4 border-l border-grey-1 ">
                          {results[fieldName]['source'] ? (
                            <div className="px-2 truncate">
                              <div className="truncate" title={results[fieldName]['source']['file'].split('/').slice(-1)[0]}>
                                {results[fieldName]['source']['file'].split('/').slice(-1)[0]},  p.{results[fieldName]['source']['source_file_page']}
                              </div>
                            </div>
                          ) : (
                            <div className="px-2 truncate">
                              n/a
                            </div>
                          )}

                        </div>
                        <div className="flex flex-row items-center w-1/4 border-l border-grey-1 ">
                          <div className="flex flex-row items-center px-2">
                            {(results[fieldName].status === 'needs_review') && (
                              <div className="flex flex-row items-center">
                                <div className="mr-2">Needs review</div>
                                {(fieldName === activeReviewField) && <Button size={BUTTON_SIZES.XSMALL} color={BUTTON_COLORS.SECONDARY_BLUE} onClick={() => saveManuallyUpdatedField(fieldName, 'approve')} label={'Approve'}/>}
                              </div>
                            )}
                            {(results[fieldName].status === 'manually_updated') && (
                              <div className="flex flex-row items-center">
                                <div>
                                  Manually updated
                                </div>
                                <CheckCircleIcon className="h-5 w-5 ml-2 opacity-60 fill-green" title="Valid" />
                              </div>
                            )}
                            {(results[fieldName].status === 'reviewed')  && (
                              <div className="flex flex-row items-center">
                                <div>
                                  Reviewed
                                </div>
                                <CheckCircleIcon className="h-5 w-5 ml-2 opacity-60 fill-green" title="Valid" />
                              </div>
                            )}
                            {(results[fieldName].status === 'invalid') && <div className="mr-2">Invalid</div>}
                            {(results[fieldName].status === 'empty') && <div className="mr-2">Empty</div>}
                            {(results[fieldName].status === 'not_found') && <div className="mr-2">Not found</div>}

                            {/* {(!results[fieldName].valid || results[fieldName].empty) ? (
                              <div className="flex flex-row items-center">
                                <ExclamationTriangleIcon className="h-5 w-5 mr-1 opacity-60 fill-yellow" title="Needs review" />
                                <div>
                                  Needs review
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-row items-center">
                                <CheckCircleIcon className="h-5 w-5 mr-1 opacity-60 fill-green" title="Valid" />
                                <div>
                                  Valid
                                </div>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <hr className="border-1 border-grey-1"/>
                    </div>
                  ))}
                  </div>
                )}
                {/* <div className="flex flex-row justify-end mt-4 mr-4">
                  <Button color={BUTTON_COLORS.BLUE} size={BUTTON_SIZES.SMALL} label={"Complete Review"} disabled={!resultsUpdated || !!savingResults} onClick={null}/>
                  <Oval className="ml-2" visible={!!savingResults} width={24} height={24} strokeWidth={5} color="#0D4BEA" secondaryColor="#0D4BEA"/>
                </div> */}
              </div>
              <div className="w-2/5 text-start border-l border-grey-1">
                {!!documents && (
                  <div>
                    <div className="flex flex-row justify-between items-center mx-6 my-2">
                      <div className="w-3/4 relative">
                        <Dropdown
                          options={documents.map((doc, index) => { return {value: doc.s3_link, label: doc.name}})}
                          onChange={(e) => onPDFChange(e)}
                          value={selectedPDF.label || selectedPDF.name}
                        />
                      </div>
                      <div className="flex flex-row ml-4 items-center">
                        <ChevronLeftIcon className="h-5 w-5 mr-2" onClick={onPreviousPageClick} />
                        <form onSubmit={(e) => handleJumpToPage(e)}>
                          <input className="border-2 rounded-md bo mr-2 w-6 border-[#DFDFDF] text-center" type="text" ref={inputRef} value={pageNumber} onChange={(e) => setPageNumber(e.target.value)}/>
                        </form>
                        <div>/ {numPages}</div>
                        <ChevronRightIcon className="h-5 w-5 ml-2" onClick={onNextPageClick} />
                      </div>
                    </div>
                    <div id="current-pdf-document" className="overflow-auto h-screen max-h-[800px] flex flex-wrap justify-center align-start border-8 border-grey-1" ref={pdfContainerRef} onWheel={handleScroll}>
                      <Document
                        file={selectedPDF.s3_link}
                        options={{ workerSrc: '/pdf.worker.js' }}
                        onLoadSuccess={async (pdf) => {
                          setNumPages(pdf.numPages);
                        }}
                        onItemClick={({ pageNumber }) => setPageNumber(pageNumber)}
                        className="bg-grey-1"
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page key={`page_${index + 1}`} pageNumber={index + 1} className="pdf-page relative mb-1">
                            <h4 className="text-center">Page {index + 1}</h4>
                          </Page>
                        ))}
                      </Document>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col py-6 w-full">
            <div className="my-auto mx-auto">
              Loading ...
            </div>
          </div>
        )}
      </div>
    </div>
  )
}