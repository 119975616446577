import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import APIService from '../../components/APIService'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../../shared/Button'
import ToolsSidebar from '../../components/ToolsSidebar'
import CookieCrumbNav from '../../components/CookieCrumbNav'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HelpText from '../../shared/HelpText';
import Dropdown from "../../shared/Dropdown";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Oval } from 'react-loader-spinner';
import ProgressBar  from '../../components/ProgressBar';
import dateUtils from '../../utils/DateUtils';
import currencyUtils from '../../utils/CurrencyUtils';
import TaskerPayoutStatusTag from '../../shared/TaskerPayoutStatusTag'


export default function Shifts({token, removeToken, saveToken}) {
  
  const navigate = useNavigate()
  const location = useLocation();

  const [shifts, setShifts] = useState(null)
  const [expandedShiftIdx, setExpandedShiftIdx] =useState(null)
  const { convertTimestampToLocalTime } = dateUtils()
  const { formatCurrency } = currencyUtils()

  useEffect(() => {
    APIService.GetShifts(token).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setShifts(resp.shifts.filter(a => (a.end_time !== null)))
    })
  }, [token])

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <ToolsSidebar removeToken={removeToken} />
        <div className="flex flex-col py-6 w-full">
          <div className="mx-6">
            <div className="flex flex-row justify-between mt-6">
              <div>
                <h1>Shifts</h1>
              </div>
            </div>
          </div>
          <hr className="mt-4 border-1 border-grey-1"/>
          <div className="flex flex-row">
            <div className="w-full text-start">
              <div className='w-full flex flex-row'>
                <div className="w-1/6">
                  <h4 className='my-1 px-6'>Shift ID</h4>
                </div>
                <div className="w-1/6">
                  <h4 className='my-1 px-6'>Start time</h4>
                </div>
                <div className="w-1/6">
                  <h4 className='my-1 px-6'>End time</h4>
                </div>
                <div className="w-1/6">
                  <h4 className='my-1 px-6'>Tasks</h4>
                </div>
                <div className="w-1/6">
                  <h4 className='my-1 px-6'>Payout</h4>
                </div>
                <div className="w-1/6">
                  <h4 className='my-1 px-6'>Payout Status</h4>
                </div>
              </div>
              <hr className="border-1 border-grey-1"/>
              {!!shifts && (
              <div>
                {shifts.map((shift, index) => (
                  <div className={"w-full hover:bg-grey-1 hover:cursor-pointer " + (expandedShiftIdx === index ? 'bg-grey-1' : '') } onClick={() => setExpandedShiftIdx(index)}>
                    <div>
                      <div className='flex flex-row py-2'>
                        <div className="w-1/6">
                          <div className='my-1 px-6'>{shift.uuid.slice(-6)}</div>
                        </div>
                        <div className="w-1/6">
                          <div className='my-1 px-6'>{convertTimestampToLocalTime(shift.start_time)}</div>
                        </div>
                        <div className="w-1/6">
                          <div className='my-1 px-6'>{convertTimestampToLocalTime(shift.end_time)}</div>
                        </div>
                        <div className="w-1/6">
                          <div className='my-1 px-6'>{shift.assignment_summary.length}</div>
                        </div>
                        <div className="w-1/6">
                          <div className='w-1/2 my-1 px-6'>{formatCurrency(shift.payout.monetary_fields)}</div>
                        </div>
                        <div className="w-1/6">
                          <div className="my-1 px-6">
                            <TaskerPayoutStatusTag status={shift.payout.status}/>
                          </div>
                        </div>

                      </div>
                      {expandedShiftIdx === index && shift.assignment_summary.length > 0 && (
                        <div className="bg-grey-1">
                          <div className='w-full flex flex-row'>
                            <div className="w-1/6">
                              <h3 className='my-1 px-6'></h3>
                            </div>
                            <div className="w-1/6">
                              <h3 className='my-1 px-6'>Task ID</h3>
                            </div>
                            <div className="w-1/6">
                              <h3 className='my-1 px-6'>Completed at</h3>
                            </div>
                            <div className="w-1/6">
                              <h3 className='my-1 px-6'>Subtasks</h3>
                            </div>
                            <div className="w-1/6">
                              <h3 className='my-1 px-6'>Payout</h3>
                            </div>
                          </div>
                          <div className="">
                            {shift.assignment_summary.map((assignment, index) => (
                              <div className='flex flex-row py-2 text-sm'>
                                <div className="w-1/6">
                                  <div className='my-1 px-6'></div>
                                </div>
                                <div className="w-1/6">
                                  <div className='my-1 px-6'>{assignment.uuid.slice(-6)}</div>
                                </div>
                                <div className="w-1/6">
                                  <div className='my-1 px-6'>{convertTimestampToLocalTime(assignment.completed_at)}</div>
                                </div>
                                <div className="w-1/6">
                                  <div className='my-1 px-6'>{assignment.num_subtasks}</div>
                                </div>
                                <div className="w-1/6">
                                  <div className='my-1 px-6'>{formatCurrency(assignment.monetary_fields)}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}