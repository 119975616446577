import * as React from "react";

const authContext = React.createContext();


function useToken() {

  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const [token, setToken] = React.useState(getToken());

  return {
    saveToken(userToken) {
      return new Promise((res) => {
        if (userToken === undefined) {
          return
        }
        localStorage.setItem('token', userToken);
        setToken(userToken);
        res();
      })
    },
    token,
    removeToken() {
      return new Promise((res) => {
        localStorage.removeItem("token");
        setToken(null);
        res();
      })
    },
    isTokenSet() {
      return !(!token && token !=="" && token !== undefined);
    }
  }
}

export default useToken;