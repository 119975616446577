import React, { useId } from 'react'

export const STATUSES = Object.freeze({
  PENDING: 'Pending',
  PAID: 'Paid',
  NONE: 'None',
  INVALID: 'Invalid status',  // not used
})

const STATUS_TAG_CLASS = Object.freeze({
  [STATUSES.PENDING]: 'text-[#5B4F49] bg-[#FCEA3E] bg-opacity-25',
  [STATUSES.PAID]: 'text-[#425341] bg-[#1AD2A6] bg-opacity-25',
  [STATUSES.NONE]: 'text-[#41130D] bg-[#F8D6D0] bg-opacity-25',
  [STATUSES.INVALID]: 'text-[#41130D] bg-[#F8D6D0] bg-opacity-25',
})

export default function TaskerPayoutStatusTag({ status }) {
  const tagId = useId()
  const validStatuses = Object.values(STATUSES)
  if (!validStatuses.includes(status)) {
    throw new TypeError(
      `${status} is not a valid status. Must be one of ${validStatuses.join(
        ', '
      )}`
    )
  }

  return (
    <div className={`inline-block py-[3px] px-2 text-sm leading-[17px] rounded-[3px] ${STATUS_TAG_CLASS[status]} overflow-hidden`}>
      {status}
    </div>
  )
}
