import { React, useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import aqueductLogo from "../images/aqueduct_logo.svg"
import APIService from '../components/APIService'
import { BoltIcon, BeakerIcon, Cog6ToothIcon, DocumentMagnifyingGlassIcon, MapIcon, } from '@heroicons/react/24/outline'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button'

export default function Sidebar({ removeToken }) {

  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  function logOut(event) {
    event.preventDefault();
    APIService.LogOut().then(resp => {
      removeToken();
      navigate("/");
    })
    .catch(error => console.log(error));
  }

  return (
    <div>
      <div className="flex flex-col justify-between w-48 min-h-screen h-full bg-stone-100 p-4">
        <div>
          <img className="my-6 pointer-events-none" src={aqueductLogo} />
          <div className={"flex flex-row mt-4 item-center hover:bg-stone-200 p-2 rounded cursor-pointer " + (location.pathname.includes("/tasks") ? "bg-stone-200" : "")} onClick={() => onNav("/tasks")}>
            {/* <BeakerIcon className="h-6 w-6 mr-2" /> */}
            <div>Tasks</div>
          </div>
          <div className={"flex flex-row mt-4 item-center hover:bg-stone-200 p-2 rounded cursor-pointer "  + (location.pathname.includes("/workflows") ? "bg-stone-200" : "")} onClick={() => onNav("/workflows")}>
            {/* <BoltIcon className="h-6 w-6 mr-2" /> */}
            <div>Workflows</div>
          </div>
          <div className={"flex flex-row mt-4 item-center hover:bg-stone-200 p-2 rounded cursor-pointer " + (location.pathname.includes("/settings") ? "bg-stone-200" : "")} onClick={() => onNav("/settings")}>
            {/* <Cog6ToothIcon className="h-6 w-6 mr-2" /> */}
            <div>Settings</div>
          </div>
        </div>
        <div>
          <hr className="mb-4 border-1 border-grey-2"></hr>
          <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.GREY} onClick={logOut} label={"Log out"} />
        </div>
      </div>
    </div>
  )
}