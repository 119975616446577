import React, { useId } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'

export const STATUSES = Object.freeze({
  IN_PROGRESS: 'In progress',
  IN_REVIEW: 'In review',
  COMPLETED: 'Completed',
  INVALID: 'Invalid status'
})

const STATUS_TAG_CLASS = Object.freeze({
  [STATUSES.IN_PROGRESS]: 'text-[#0D4BEA] bg-[#BDEAFF] bg-opacity-25',
  [STATUSES.COMPLETED]: 'text-[#425341] bg-[#1AD2A6] bg-opacity-25',
  [STATUSES.IN_REVIEW]: 'text-[#5B4F49] bg-[#FCEA3E] bg-opacity-25',
  [STATUSES.INVALID]: 'text-[#41130D] bg-[#F8D6D0] bg-opacity-25',
})

const STATUS_TAG_TOOLTIP_BACKGROUND_CLASS = Object.freeze({
  [STATUSES.IN_PROGRESS]: 'bg-[#BDEAFF]',
  [STATUSES.COMPLETED]: 'bg-[#1AD2A6]',
  [STATUSES.IN_REVIEW]: 'bg-[#FCEA3E]',
  [STATUSES.INVALID]: 'bg-[#F8D6D0]',
})

export default function TaskStatusTag({ status }) {
  const tagId = useId()
  const validStatuses = Object.values(STATUSES)
  if (!validStatuses.includes(status)) {
    throw new TypeError(
      `${status} is not a valid status. Must be one of ${validStatuses.join(
        ', '
      )}`
    )
  }

  return (
    <div className={`inline-block py-[3px] px-2 text-sm leading-[17px] rounded-[3px] ${STATUS_TAG_CLASS[status]} overflow-hidden`}>
      {status}
    </div>
  )
}
