import { React, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronRightIcon} from '@heroicons/react/24/outline'


export default function CookieCrumbNav({ paths, currentPathName }) {

  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();

  function onNav(loc) {
    if (location.pathname !== loc) {
      navigate(loc);
    }
  }

  return (
    <div>
      <div className="flex flex-row text-sm">
        {paths.map((path, index) => (
          <div className="flex flex-row items-center">
            <div onClick={() => onNav(path.location)} className="font-[#6E6E6E] hover:text-grey-2 hover:cursor-pointer">{path.name}</div>
            <ChevronRightIcon className="h-4 w-4 mx-2" />
          </div>
        ))}
        <div className="text-[#5D7797] font-bold">
          {currentPathName}
        </div>
      </div>
    </div>
  )
}