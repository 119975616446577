import React, { useRef} from "react";
import ReactDom from "react-dom";
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button';

export const ConfirmReviewModal = ({ token, removeToken, saveToken, submitErrors, setSubmitErrors, setShowModal, onSubmit }) => {
  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  //render the modal JSX in the portal div.
  return ReactDom.createPortal(
    <div className="w-1/3 modal-container rounded-md shadow-lg border-2 p-5" ref={modalRef} onClick={closeModal}>
      <div className="mx-5">
        <div className="">
          <div className="text-right">
            <button className="text-red-500 hover:text-red-400" onClick={() => setShowModal(false)} >Close</button>
          </div>
          <div>
            <h2 className="text-xl my-4 mb-6">Validation Errors Found</h2>
            <div className="text-gray-700 text-sm mb-4">
              We detected validation errors with your manual review. If these are intentional, please proceed. Otherwise, please close this and return to editing.
            </div>
            {submitErrors.map((error, index) => (
              <div key={index} className="text-left my-1 text-sm font-semibold text-red-500">{index + 1}) {error}</div>
            ))}
            <div className='mt-6 mb-2 text-center'>
              <Button size={BUTTON_SIZES.MEDIUM} color={BUTTON_COLORS.BLUE} onClick={onSubmit} label={"Submit fields"} />
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};