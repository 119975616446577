import { BrowserRouter } from 'react-router-dom'
import ToolsRouter from './routers/ToolsRouter'
import AppRouter from './routers/AppRouter'
import './App.css';


function App() {

  function getRouter() {
    const subdomain = window.location.hostname.split(".")[0];
    // app.aqueduct.com or app.localhost:3000 returns the app router
    // aqueduct.com or tools.localhost:3000 returns the homepage router
    if (subdomain === "app") {
        return AppRouter
    } else if (subdomain === "tools") {
        return ToolsRouter
    }
  }

  const CurrentRouter = getRouter()
  return (
    <div className="App">
      <BrowserRouter>
        <CurrentRouter />
      </BrowserRouter>
    </div>
  )
}

export default App;
