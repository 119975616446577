import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";

import APIService from '../../components/APIService'

import aqueductImg from "../../images/aqueduct_water.webp"
import aqueductToolsLogo from "../../images/aqueduct_tools_logo.svg"
import backgroundImg from "../../images/homepage_background.webp"
import duckImg from "../../images/duck.svg"
import { Oval } from 'react-loader-spinner'
import TextInput from '../../shared/TextInput'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../../shared/Button'


export default function ToolsLogin({saveToken}) {

  const navigate = useNavigate();
  const { state } = useLocation();

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [loggingIn, setLoggingIn] = useState(false);

  function onHomepageNav() {
    navigate("/");
  }

  const onEmailChange = (email) => {
    setEmail(email);
  }

  const onPasswordChange = (password) => {
    setPassword(password);
  }

  function logIn(event) {
    setLoggingIn(true)
    APIService.ToolsLogIn({email, password})
      .then(resp => {
        if (!!resp.error) {
          setErrorMessage(resp.error);
        } else {
          saveToken(resp.access_token);
          setPassword("");
          setErrorMessage("");
          navigate(state?.path || "/tasks");
        }
        setLoggingIn(false)
      })
      .catch(error => console.log(error));
    event.preventDefault();
  }

  return (
    <div className="bg-cover min-h-screen" style={{backgroundImage: `url(${backgroundImg})`}}>
      <div className="absolute bottom-0 right-0 max-w-4xl">
        <img className="pointer-events-none" src={aqueductImg} />
      </div>
      <div className="absolute bottom-20 right-20 invisible md:visible">
        <img className="pointer-events-none" src={duckImg}/>
      </div>
      <div className="absolute w-full">
        <div className="flex flex-col">
          <div className="mt-12 ml-12">
            <img className="pointer-events-none" src={aqueductToolsLogo} />
          </div>
          <div className="flex flex-row place-content-center mt-12 md:mt-24">
            <form action="" className="space-y-3 w-1/2 md:w-1/3">
              <div>
                <label htmlFor="" className="text-left text-sm font-bold text-gray-600 block">Email</label>
                <TextInput onChange={e => onEmailChange(e.target.value)} value={email} error={errorMessage !== '' ? ' ' : ''}/>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="" className="text-sm font-bold text-gray-600 block">Password</label>
                </div>
                <TextInput onChange={e => onPasswordChange(e.target.value)} value={password} type={"password"} error={errorMessage}/>
              </div>
              <div className="flex flex-row items-center">
                <button onClick={logIn} disabled={!!loggingIn} className="w-full py-3 px-4 bg-light-blue hover:bg-blue disabled:bg-gray-400 text-white font-bold rounded">Sign In</button>
                <Oval className="ml-2" visible={!!loggingIn} width={36} height={36} strokeWidth={5} color="#4F46E5" secondaryColor="#4F46E5"/>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="w-full py-6 absolute bottom-0 left-0 bg-white">
        <div className="flex flex-col md:flex-row justify-between items-center px-12">
          <div className="text-lg text-[#303959]">
            © 2023 Aqueduct Technologies, Inc.
          </div>
          {/* <div className="text-lg text-[#303959]">
            Privacy Policy | Terms & Conditions
          </div> */}
        </div>
      </div>
    </div>
  )
}