import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'
import CookieCrumbNav from '../components/CookieCrumbNav'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button'
import TextInput from '../shared/TextInput'
import Dropdown from "../shared/Dropdown"
import { PlusIcon } from '@heroicons/react/24/outline'
import { Oval } from 'react-loader-spinner'


export default function WorkflowUpdate({token, removeToken, saveToken, isCreate}) {

  const OUTPUT_TYPES = [
    {'value': 'csv', 'label': 'CSV'},
    {'value': 'json', 'label': 'JSON'},
    {'value': 'api', 'label': 'API'},
  ]
  const REVIEW_TYPES = [
    {'value': 'all', 'label': 'All'},
    {'value': 'empty', 'label': 'Empty Fields'},
    {'value': 'invalid', 'label': 'Invalid Fields'},
  ]
  const FIELD_TYPES = [
    {'value': 'text', 'label': 'Text'},
    {'value': 'dollar', 'label': 'Dollar'},
    {'value': 'name', 'label': 'Name'},
    {'value': 'percent', 'label': 'Percent'},
    {'value': 'address', 'label': 'Address'},
    {'value': 'date', 'label': 'Date'},
  ]

  const navigate = useNavigate()
  const { workflowUUID } = useParams()
  const [workflow, setWorkflow] = useState(null)
  const [workflowName, setWorkflowName] = useState('')
  const [outputType, setOutputType] = useState(null)
  const [reviewTypes, setReviewTypes] = useState([])
  const [extractionFields, setExtractionFields] = useState([])
  const [newReviewType, setNewReviewType] = useState(null)
  const [workflowEdited, setWorkflowEdited] = useState(false)
  const [addingField, setAddingField] = useState(false)
  const [newField, setNewField] = useState({type: {}})
  const [updatingWorkflow, setUpdatingWorkflow] = useState(false)
  const [workflowIsLoaded, setWorkflowIsLoaded] = useState(false)

  const cookieCrumbNavPaths = (isCreate ? [
    {
      'name': 'Workflows',
      'location': '/workflows',
  },
  ] : [
    {
        'name': 'Workflows',
        'location': '/workflows',
    },
    {
        'name': workflow ? workflow.name : '',
        'location': workflow ?  '/workflows/' + workflow.uuid : '',
    }
  ])

  useEffect(() => {
    if (!isCreate) {
      APIService.GetWorkflowByUUID(token, workflowUUID).then((resp) => {
        if (resp.msg === 'Token has expired') {
          removeToken()
          navigate('/login')
        }
        if (!!resp.access_token) {
          saveToken(resp.access_token)
        }
        setWorkflow(resp.workflow)
        setWorkflowName(resp.workflow.name)
        setOutputType(resp.workflow.output)
        setReviewTypes(resp.workflow.review_types)
        setExtractionFields(resp.workflow.fields)
        setWorkflowIsLoaded(true)
      })
    }
  }, [token])

  function onNameChange(e) {
    setWorkflowName(e.target.value)
    setWorkflowEdited(true)
  }

  function onOutputTypeChange(e) {
    setOutputType(e)
    setWorkflowEdited(true)
  }

  function onReviewTypeAdd() {
    if (newReviewType !== null) {
      var newReviewTypes = [...reviewTypes, newReviewType]
      setWorkflowEdited(true)
      setReviewTypes(newReviewTypes)
      setNewReviewType(null)
    }
  };

  function onReviewTypeOptionChange(e) {
    setNewReviewType(e)
  }

  function onReviewTypeDelete(index) {
    var updatedReviewTypes = [...reviewTypes]
    updatedReviewTypes.splice(index, 1)
    setWorkflowEdited(true)
    setReviewTypes(updatedReviewTypes)
  }

  function getReviewTypeOptions() {
    var reviewTypeOptions = []
    const existingReviewTypes = reviewTypes.map(obj => obj['value'])
    for (var i = 0; i < REVIEW_TYPES.length; i ++) {
      if (!existingReviewTypes.includes(REVIEW_TYPES[i]['value'])) {
        reviewTypeOptions.push(REVIEW_TYPES[i])
      }
    }
    return reviewTypeOptions
  }

  function onNewFieldAdd(e) {
    setAddingField(true)
  }

  function onFieldDelete(e, index) {
    var updatedExtractionFields = [...extractionFields]
    updatedExtractionFields.splice(index, 1)
    setExtractionFields(updatedExtractionFields)
    setWorkflowEdited(true)
  }

  function onNewFieldSave() {
    setExtractionFields([...extractionFields, newField])
    setAddingField(false)
    setNewField({type: {}})
    setWorkflowEdited(true)
  }

  function onUpdateNewField(key, value) {
    setNewField({...newField, [key]: value})
    setWorkflowEdited(true)
  }

  function onUpdateFieldByName(e, index, fieldName) {
    var updatedExtractionFields = [...extractionFields]
    updatedExtractionFields[index][fieldName] = e.target.value
    setExtractionFields(updatedExtractionFields)
    setWorkflowEdited(true)
  }

  function onUpdateFieldType(e, index) {
    var updatedExtractionFields = [...extractionFields]
    updatedExtractionFields[index]['type'] = e
    setExtractionFields(updatedExtractionFields)
    setWorkflowEdited(true)
  }

  function onCreateOrEdit(e) {
    e.preventDefault();
    setUpdatingWorkflow(true)
    const newWorkflow = {
      uuid: workflow === null ? null : workflow.uuid,
      name: workflowName,
      fields: extractionFields,
      review_types: reviewTypes,
      output: outputType,
    }
    APIService.CreateOrEditWorkflow(token, newWorkflow).then(resp => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setUpdatingWorkflow(false)
      if (resp.is_create) {
        navigate('/workflows');
      } else {
        navigate('/workflows/' + resp.workflow.uuid);
      }
    })
    .catch(error => console.log(error));
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <div className="flex flex-col px-12 py-6 w-full">
          <CookieCrumbNav paths={cookieCrumbNavPaths} currentPathName={!!isCreate ? "Create workflow" : "Edit workflow"} />
          <div className="flex flex-row justify-between mt-6">
            <h1>{!!isCreate ? "Create workflow" : "Edit workflow"}</h1>
            <div className="flex flex-row items-center h-12">
              <Button size={BUTTON_SIZES.MEDIUM} color={BUTTON_COLORS.BLUE} onClick={onCreateOrEdit} label={"Save workflow"} disabled={!workflowEdited || updatingWorkflow}/>
              <Oval className="ml-2" visible={!!updatingWorkflow} width={36} height={36} strokeWidth={5} color="#0D4BEA" secondaryColor="#0D4BEA"/>
            </div>
          </div>
          <hr className="my-4 border-1 border-grey-2"/>
          {(!!workflowIsLoaded || !!isCreate) && (
            <div className="flex flex-col text-start">
              <div className="mt-4">
                <div className="w-1/2">
                  <TextInput onChange={onNameChange} value={workflowName} title={"Workflow name"}/>
                </div>
                <div className="flex flex-col mt-4">
                  <h4 className="mb-2">Output type</h4>
                  <div className="w-1/2 relative">
                    <Dropdown options={OUTPUT_TYPES} value={outputType} onChange={(e) => onOutputTypeChange(e)} placeholder={"Choose output type"}/>
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-2">Review Type</h4>
                  <div className="w-1/2">
                    <ul>
                      {reviewTypes.map((reviewType, idx) => (
                        <li key={idx}
                            className={`inline-block mr-2 py-[3px] px-2 text-sm leading-[17px] rounded-[3px] text-white bg-indigo-500 overflow-hidden hover:bg-red-300 hover:cursor-pointer hover:text-red-500`}
                            onClick={() => onReviewTypeDelete(idx)}
                        >
                          {reviewType.label}
                        </li>
                        ))}
                    </ul>
                  </div>
                  <div className="flex-row items-center w-1/2 mt-2 flex">
                    <div className="w-full relative">
                      <Dropdown options={getReviewTypeOptions()} value={newReviewType} onChange={(e) => onReviewTypeOptionChange(e)} placeholder={"Add additional review types"} disabled={getReviewTypeOptions().length === 0}/>
                    </div>
                    <div className="hover:cursor-pointer" onClick={onReviewTypeAdd}>
                      <PlusIcon className="h-5 w-5 ml-2 " />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <h2>Extraction fields</h2>
              </div>
              <hr className="my-4 border-1 border-grey-2  w-full"/>
              {extractionFields.map((field, index) => (
                <div className="flex flex-col text-start mb-8">
                  <div className="flex flex-row justify-between">
                    <h3>{field.name}</h3>
                    <Button size={BUTTON_SIZES.XSMALL} color={BUTTON_COLORS.ORANGE} onClick={(e) => onFieldDelete(e, index)} label={"Delete"}/>
                  </div>
                  <div className="flex flex-col mt-2">
                    <div className="flex flex-row">
                      <div className="w-1/2 mr-2">
                        <TextInput onChange={(e) => onUpdateFieldByName(e, index, 'name')} value={field.name} title={"Field name"}/>
                      </div>
                      <div className="ml-2 w-1/2">
                        <TextInput onChange={(e) => onUpdateFieldByName(e, index, 'output_label')} value={field.output_label} title={"Output field"}/>
                      </div>
                    </div>
                    <div className="flex flex-row mt-4">
                      <div className="w-1/2 mr-2 relative">
                        <h4 className="mb-2">Type</h4>
                        <Dropdown options={FIELD_TYPES} value={field.type.label} onChange={(e) => onUpdateFieldType(e, index)}/>
                      </div>
                      <div className="ml-2 w-1/2">
                        <TextInput onChange={(e) => onUpdateFieldByName(e, index, 'description')} value={field.description} title={"Description"}/>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-8 border-1 border-grey-2  w-full"/>
              </div>
              ))}
              {!!addingField && (
                <div className="flex flex-col text-start mb-8">
                  <h3>{newField.name}</h3>
                  <div className="flex flex-col mt-2">
                    <div className="flex flex-row">
                      <div className="w-1/2 mr-2">
                        <TextInput onChange={(e) => onUpdateNewField('name', e.target.value)} value={newField.name} title={"Field name"}/>
                      </div>
                      <div className="ml-2 w-1/2">
                        <TextInput onChange={(e) => onUpdateNewField('output_label', e.target.value)} value={newField.output_label} title={"Output field"}/>
                      </div>
                    </div>
                    <div className="flex flex-row mt-4">
                      <div className="w-1/2 mr-2 relative">
                        <h4 className="mb-2">Type</h4>
                        <Dropdown options={FIELD_TYPES} value={newField.type.label} onChange={(e) => onUpdateNewField('type', e)}/>
                      </div>
                      <div className="ml-2 w-1/2">
                        <TextInput onChange={(e) => onUpdateNewField('description', e.target.value)} value={newField.description} title={"Description"}/>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-6">
                {!!addingField ? (
                  <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.BLUE} onClick={() => onNewFieldSave()} label={"Save new field"}/>
                ) : (
                  <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.SECONDARY_BLUE} onClick={() => onNewFieldAdd()} label={"Add new field"}/>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}