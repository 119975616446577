import React from 'react'

/**
 * component to render progress bars
 *
 */
export default function ProgressBar({ statuses, statusCounts, statusColors }) {
  const totalCount = statusCounts.reduce((partialSum, a) => partialSum + a, 0);
  const proportions = statuses.map((_, i) => (statusCounts[i] || 0) / (totalCount || 1) * 100);

  return (
    <div>
      <div className="flex w-full rounded-full h-2 overflow-hidden">
        {statuses.map((status, index) => (
            <div style={{width: proportions[index] + '%'}} className={`transition-[width] duration-[1500ms] h-full bg-${statusColors[index]} bg-opacity-50`}></div>
        ))}
      </div>
    </div>
  )
}
