import React from 'react'
import ReactDropdown from 'react-dropdown'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'


export default function Dropdown({ options, onChange, value, placeholder, disabled }) {
  return (
    <ReactDropdown
      options={options}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      arrowClosed={<ChevronDownIcon />}
      arrowOpen={<ChevronUpIcon />}
    />
  )
}
