import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'
import CookieCrumbNav from '../components/CookieCrumbNav'
import Button, { BUTTON_SIZES, BUTTON_COLORS } from '../shared/Button'
import TextInput from '../shared/TextInput'
import Dropdown from "../shared/Dropdown"
import { PlusIcon } from '@heroicons/react/24/outline'
import { Oval } from 'react-loader-spinner'


export default function TaskUpdate({token, removeToken, saveToken, isCreate}) {


  const navigate = useNavigate()
  const { taskUUID } = useParams()
  const [task, setTask] = useState(null)
  const [workflows, setWorkflows] = useState([])
  const [taskName, setTaskName] = useState('')
  const [file, setFile] = useState(null)
  const [selectedWorkflow, setSelectedWorkflow] = useState(null)
  const [updatingTask, setUpdatingTask] = useState(false)
  const [taskEdited, setTaskEdited] = useState(false)
  const [workflowsAreLoaded, setWorkflowsAreLoaded] = useState(false)
  const [taskIsLoaded, setTaskIsLoaded] = useState(false)

  const cookieCrumbNavPaths = (isCreate ? [
    {
      'name': 'Tasks',
      'location': '/tasks',
  },
  ] : [
    {
        'name': 'Tasks',
        'location': '/tasks',
    },
    {
        'name': task ? task.name : '',
        'location': task ?  '/workflows/' + task.uuid : '',
    }
  ])

  useEffect(() => {
    if (!isCreate) {
      APIService.GetTaskByUUID(token, taskUUID).then((resp) => {
        if (resp.msg === 'Token has expired') {
          removeToken()
          navigate('/login')
        }
        if (!!resp.access_token) {
          saveToken(resp.access_token)
        }
        setTask(resp.task)
        setTaskName(resp.task.name)
        setFile(resp.task.file)
        setTaskIsLoaded(true)
      })
    }
  }, [token])

  useEffect(() => {
    APIService.GetLatestWorkflows(token).then((resp) => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setWorkflows(resp.workflows)
      setWorkflowsAreLoaded(true)
    })
  }, [token])

  function onNameChange(e) {
    setTaskName(e.target.value)
    setTaskEdited(true)
  }

  function onFileChange(event) {
    setFile(event.target.files[0]);
    setTaskEdited(true)
  }

  function onWorkflowChange(e) {
    setSelectedWorkflow(e)
    setTaskEdited(true)
  }

  function onCreateTask(e) {
    e.preventDefault();
    setUpdatingTask(true)
    const formData = new FormData();
    formData.append('name', taskName);
    formData.append('workflowName', selectedWorkflow.label)
    formData.append('workflowUUID', selectedWorkflow.value)
    formData.append('file', file);
    APIService.CreateTask(token, formData).then(resp => {
      if (resp.msg === 'Token has expired') {
        removeToken()
        navigate('/login')
      }
      if (!!resp.access_token) {
        saveToken(resp.access_token)
      }
      setUpdatingTask(false)
      navigate('/tasks/' + resp.task.uuid);      
    })
    .catch(error => console.log(error));
  }

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function onFileClick() {
    document.getElementById('selectedFile').click()
  }

  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <div className="flex flex-col px-12 py-6 w-full">
          <CookieCrumbNav paths={cookieCrumbNavPaths} currentPathName={!!isCreate ? "Create task" : "Edit task"} />
          <div className="flex flex-row justify-between mt-6">
            <h1>{!!isCreate ? "Create task" : "Edit task"}</h1>
            <div className="flex flex-row items-center h-12">
              <Button size={BUTTON_SIZES.MEDIUM} color={BUTTON_COLORS.BLUE} onClick={onCreateTask} label={"Save task"} disabled={!taskEdited || updatingTask}/>
              <Oval className="ml-2" visible={!!updatingTask} width={36} height={36} strokeWidth={5} color="#0D4BEA" secondaryColor="#0D4BEA"/>
            </div>
          </div>
          <hr className="my-4 border-1 border-grey-2"/>
          {(!!taskIsLoaded || !!isCreate) && (
            <div className="flex flex-col text-start">
              <div className="mt-4">
                <div className="w-1/2">
                  <TextInput onChange={onNameChange} value={taskName} title={"Task name"}/>
                </div>
                <div className="flex flex-col mt-4">
                  <h4 className="mb-2">Workflow</h4>
                  <div className="w-1/2 relative">
                    <Dropdown options={workflows.map(workflow => { return {value: workflow.uuid, label: workflow.name}})} value={selectedWorkflow} onChange={(e) => onWorkflowChange(e)} placeholder={"Choose a workflow"}/>
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-2">Documents</h4>
                  <div className="w-1/2">
                    <div className="flex flex-row items-center">
                      <input id="selectedFile" style={{"display": "none"}} type="file" accept=".zip" onChange={onFileChange} />
                      <Button size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.SECONDARY_BLUE} onClick={onFileClick} label={"Choose file"}/>
                      <div className="ml-4" >{!!file ? file.name + " (" + formatBytes(file.size) + ")" : ''}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}