import React, { useState } from 'react'
import APIService from '../components/APIService'
import Sidebar from '../components/Sidebar'

// A PLAYGROUND TO TRY RANDOM STUFF WITHOUT INTERFERING WITH THE DEMO
// Currently using this as an easy portal to execute a subtask for both performance testing
// and convenience reasons
export default function Playground({token, removeToken, saveToken}) {
  

  const [subtaskId, setSubtaskId] = useState(null);

  const handleFieldChange = (value) => {
    setSubtaskId(value);
  };

  const executeSubtask = () => {
    APIService.ExecuteSubtask(token,
      {
        id: subtaskId,
      }
    ).then((resp) => {
      console.log('Submitted')
    })
  }


  return (
    <div className="grow">
      <div className='flex flex-row'>
        <Sidebar removeToken={removeToken} />
        <div className='flex flex-col m-6'>
          <div className="text-start font-bold text-2xl">The Playground</div>
          <div className="mt-12 text-left font-semibold">Subtask UUID</div>
          <input
            className="mt-2 px-2 border border-gray-300 bg-indigo-50 rounded-sm"
            type="text"
            id="subtask_id"
            value={subtaskId}
            onChange={e => handleFieldChange(e.target.value)}
          />
          <button
            className="flex flex-row mt-8 mb-6 opacity-90 bg-green hover:bg-dark-green text-white font-bold py-2 px-8 rounded-md"
            type="submit"
            onClick={executeSubtask}
          >
              Execute Subtask
          </button>
        </div>
      </div>
    </div>
  )
}