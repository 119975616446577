import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import ToolsLogin from "../routes/tools/login";
import Task from "../routes/tools/task";
import Tasks from "../routes/tools/tasks";
import useToken from '../utils/UseToken';
import Settings from "../routes/tools/settings";
import Shifts from "../routes/tools/shifts";



export default function ToolsRouter() {

  const { saveToken, token, removeToken, isTokenSet } = useToken();

  function RequireAuth({ children }) {
    const location = useLocation();
    const authed = isTokenSet();
    return authed === true ? children : <Navigate to="/login" state={{ path: location.pathname }} />;
  }

  return (
    <div className="ToolsRouter">
      <Routes>
      <Route path="tasks" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Tasks token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="tasks/:assignmentUUID" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Task token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="shifts" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Shifts token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="settings" element={
          <RequireAuth>
            <div className="flex flex-row min-h-screen">
              <Settings token={token} removeToken={removeToken} saveToken={saveToken}/>
            </div>
          </RequireAuth>
        } />
        <Route path="/" element={ !isTokenSet() ? <ToolsLogin saveToken={saveToken}/> : <Navigate to="/tasks"/> } />
        <Route path="/login" element={ !isTokenSet() ? <ToolsLogin saveToken={saveToken}/> : <Navigate to="/tasks"/> } />
      </Routes>
    </div>
  )
}