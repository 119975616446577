import React from 'react'

/**
 * component to render aggregate status data for tasks
 *
 */
export default function TaskStatus({ taskStatuses, title, titleSize }) {
  const totalCount = Object.values(taskStatuses).reduce((sum, cur) => sum + cur, 0)
  const statuses = ['In progress', 'In review', 'Completed']
  const statusCounts = statuses.map(status => taskStatuses[status] || 0)
  const statusFillClass = ['fill-light-blue', 'fill-yellow', 'fill-green']
  const proportions = statuses.map((_, i) => (statusCounts[i] || 0) / (totalCount || 1) * 100);

  return (
    <div>
      {title !== "" && (
        <div className="flex justify-between font-bold text-xl">

          {titleSize === "h1" && (<h1>{title}</h1>) }
          {titleSize === "h2" && (<h2>{title}</h2>) }
          <div className="ml-auto">{totalCount}</div>
        </div>
      )}
      <div className="flex w-full rounded-full h-2 mt-4 overflow-hidden">
        <div style={{width: proportions[0] + '%'}} className='transition-[width] duration-[1500ms] h-full bg-light-blue bg-opacity-50'></div>
        <div style={{width: proportions[1] + '%'}} className='transition-[width] duration-[1500ms] h-full bg-yellow bg-opacity-50'></div>
        <div style={{width: proportions[2] + '%'}} className='transition-[width] duration-[1500ms] h-full bg-green bg-opacity-50'></div>
      </div>
      <div className="flex justify-between mt-4 uppercase font-semibold text-xxs text-[#686972] mb-2.5">
        <h4>Status</h4>
        <h4>Count</h4>
      </div>
      {statuses.map((status, index) => (
        <div
          key={status}
          className="flex justify-between text-sm font-semibold border-t border-t-grey-2 py-2.5 px-1"
        >
          <div className="flex items-center">
            <svg
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              className={`${statusFillClass[index]} w-1.5 h-1.5 mr-2.5 rounded-full`}
            >
              <circle cx="50" cy="50" r="50" />
            </svg>
            {status}
          </div>
          <div>{statusCounts[index] || 0}</div>
        </div>
      ))}
    </div>
  )
}
